import { Component, OnInit, ViewEncapsulation, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { HttpMethod, HttpServiceCall } from '../shared/service/http-call.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SettingDialogPopupComponent } from '../shared/setting-dialog-popup/setting-dialog-popup.component';
import { Host, GridType, SPAManagementBreakPoint, SelectType, Maxlength, RetailFunctionalities } from '../shared/globalsContant'; // CONSTANT FILE ADD ANY CONSTANT VALUE
import { RetailLocalization } from '../common/localization/retail-localization';
import { BreakPointAccess } from '../shared/service/breakpoint.service';
import { RetailUtilities } from '../shared/utilities/retail-utilities';
import { BaseResponse, popupConfig, MoreFilterOptions, OutletSubProperty } from '../shared/business/shared.modals';
import { Subscription, ReplaySubject } from 'rxjs';
import { Commission, CommissionableRetailItem, CommissionDetailsUI, CommissionDetails, CommissionSetupRequest, CommissionSetupResponse, CommissionSetupType, CommissionOn, UpdateCommissionSetupValue } from './commission-setup.modals';
import { ViewMoreServiceService } from '../shared/view-more/view-more-service.service';
import { CommissionSetupService } from './commission-setup.service';
import { RetailSetupService } from '../retail-setup/retail-setup.service';
import { RETAILSETUP } from '../retail.modals';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { RetailPropertyInformation } from '../common/services/retail-property-information.service';
import { RetailPopupComponent } from '../retail-popup/retail-popup.component';
import { RetailFunctionalityBusiness } from '../shared/business/retail-functionality.business';
import { AlertMessagePopupComponent } from '../shared/alert-message-popup/alert-message-popup.component';
import { isMatch } from 'lodash';
@Component({
  selector: 'app-retail-commission-setup',
  templateUrl: './commission-setup.component.html',
  styleUrls: ['./commission-setup.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [RetailPopupComponent]
})
export class RetailCommissionSetupComponent implements OnInit, OnDestroy {
  @Input() staffId = 0;
  relativePlacehoder: string;
  FormGrp: UntypedFormGroup;
  checkedCommissionsArr: any = [];
  CommissionArr: any = [];
  selectedcommiLocArr: any = [];
  CommissioncategoryArr: any[] = [];
  CommissionItemArr: CommissionableRetailItem[] = [];
  selectedcategoryArr: any = [];
  selectedItemArr: any = [];
  tableoptions: any;
  DataArray: any[];
  selectedArr: any = [];
  DataType: string;
  SelectedCommissionType: any;
  private commissionGridData: CommissionDetailsUI[] = [];
  captions: any;
  public IsReadOnly = false;
  IsExitsCategory = false;
  IsExitsItem = false;
  HidePopup = true;
  public itemFilteredArr = [];
  public categoryFilteredArr = [];
  commissiontype = [];
  subscription: Subscription;
  SGiftItemCategoryArr: any[];
  SVisualSpaCategoryArr: any[];
  filterOptions: MoreFilterOptions;
  applyBtn: any = this.localization.captions.setting.Apply;
  initialLoads = true;
  callCounter = 0;
  outlets: OutletSubProperty[] = [];
  selectedOutlet: OutletSubProperty[] = [];
  defaultClassId = 1;
  noOfLocations: any;
  SelectType: any = SelectType;
  commissionMaxLength = Maxlength.FLATAMOUNT;
  isDisabled = 'true';
  commissionOn = [];
  afterDiscount = [];
  sortByValues = [];
  sortOrder = [];
  flatAmountMaxLength = Maxlength.FLATAMOUNT;
  percentageMaxLength = Maxlength.PERCENTAGE;
  enableAdvSearch = true;
  apiCycle = 0;
  recordsPerAPICall = 20;
  isLoading = false;
  isScrollPositionReset = false;
  isAllCommissionValueModified: boolean = false;
  isAllCommissionThresholdModified: boolean = false;
  isAllCommissionAfterDiscountModified: boolean = false;
  isAllCommissionOnModified: boolean = false;
  applyAllCommissionType: number = 0;
  updateAllCommissionValue: number = 0;
  updateAllCommissionThersholdValue: number = 0;
  updateAllCommissionOnValue: number = 0;
  updateAllCommissionAfterDiscountValue: boolean = false;
  commissionSetupUpdateAllRequest: CommissionSetupRequest;
  @Output() isCommissionModified: EventEmitter<any> = new EventEmitter();
  @Output() modifiedCommissionValue: EventEmitter<any> = new EventEmitter();
  isAllItemsSelected = false;
  @Input() fromScreen;
  @Input() isEdit;
  @Output() closeRetailDialog: EventEmitter<boolean> = new EventEmitter<boolean>();
  selectedOutletMgrArr: number;
  itemsetupCommissions: Commission[] = [];
  destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  tableData: CommissionDetailsUI[] = [];
  retailSetupConstant = RETAILSETUP;
  public _utilities: RetailUtilities;
  validateBtnSubscription: Subscription;
  validateBtn: any;
  commissionsChanges: Subscription;
  functionalities: { [key: string]: boolean } = {};
  showOutletsSelection: boolean;
  showOutletInGrid: boolean;
  ShowRetailSetupRoomTypesAssignmentTab: boolean;
  defaultOutlet: number;
  floatLabel: string;
  throttleTime: number=1000;
  currencySymbol: string;

  constructor(private http: HttpServiceCall, private Form: UntypedFormBuilder, public _ViewMoreServiceService: ViewMoreServiceService,
    private dialog: MatDialog, private business: CommissionSetupService, private retailSetupService: RetailSetupService,
    private BPoint: BreakPointAccess, private utils: RetailUtilities, public localization: RetailLocalization, private PropertyInfo: RetailPropertyInformation,
    private dialogRef: MatDialogRef<RetailPopupComponent>, public func: RetailFunctionalityBusiness,
    private commissionService: CommissionSetupService) {
    this.business.tabLoaderEnable.next(true);
    this.localization.SetLocaleBasedProperties();
    this.defaultOutlet = this.PropertyInfo.GetDefaultOutlet();
    this.floatLabel = this.localization.setFloatLabel;
    this.currencySymbol=this.localization.currencySymbol;
  }

  async ngOnInit() {

    this.FormGrp = this.Form.group({
      ClassType: this.fromScreen == 'THERAPIST' ? '0' : '1',
      CommissionPayable: '',
      CommissionType: '',
      CommissionValue: '',
      selectedCC: '',
      Threshold: '',
      listOrder: '',
      itemDescription: '',
      threshold: '',
      flatAmount: '',
      percentage: '',
      commissionOn: '',
      afterDiscount: '',
      retailCategory: '',
      sortBy: '',
      sortOrder: ''
    });
    this.captions = this.localization.captions.setting;
    this.sortByValues = [
      { id: 1, description: this.captions.Description }
    ];
    this.commissionOn = [
      { id: 1, description: this.captions.Items },
      { id: 2, description: this.captions.Revenue }
    ];
    this.afterDiscount = [
      { id: 1, description: this.captions.Yes },
      { id: 2, description: this.captions.No }
    ];
    this.OnSortByChange({ value: 1 });
    this.commissiontype = [{ 'name': 'Threshold', value: this.captions.Threshold },
    { 'name': 'Flat Amount', value: this.captions.FlatAmount },
    { 'name': 'Percentage', value: this.captions.Percentage },
    { 'name': 'CommissionOn', value: this.captions.CommissionOn },
    { 'name': 'AfterDiscount', value: this.captions.AfterDiscount }];
    const access = this.BPoint.GetBreakPoint([SPAManagementBreakPoint.CommissionClassSetup]).result;
    const viewOnly = access[0].view;
    if (viewOnly) {
      this.IsReadOnly = true;
      this.utils.disableControls(this.FormGrp);
    }
    
    this.functionalities = await this.func.getRetailFunctionality();
    if (this.fromScreen && this.fromScreen === RETAILSETUP) {
      this.showOutletsSelection = this.functionalities[RetailFunctionalities.ShowOutletSelectionFieldInCommisionTabOnRetailItemCreationScreen] || false;
      this.showOutletInGrid = this.functionalities[RetailFunctionalities.ShowOutletFieldInCommisionGridOnRetailItemCreationScreen] || false;
      this.ShowRetailSetupRoomTypesAssignmentTab = this.functionalities[RetailFunctionalities.ShowRetailSetupRoomTypesAssignmentTab] || false;
    }
    else {
      this.showOutletsSelection = this.functionalities[RetailFunctionalities.ShowOutletSelectionFieldInCommisionSetupScreen] || false;
      this.showOutletInGrid = this.functionalities[RetailFunctionalities.ShowOutletFieldInCommisionSetupGrid] || false;
    }

    if (!this.showOutletsSelection && this.defaultOutlet === 0) {
      this.func.getDefaultOutlet().then(x => {
        this.defaultOutlet = x;
        if (this.defaultOutlet > 0) {
          this.PropertyInfo.SetDefaultOutlet(this.defaultOutlet);
        }
      });
    }

    if (this.fromScreen && this.fromScreen === RETAILSETUP) {
      this.retailSetupService.selectedOutletMgrArr.pipe(takeUntil(this.destroyed$)).subscribe(outlet => {
        let existingOutletsWithCommissions = this.business?.commissionGridData?.filter(f => f.outletId).map(m => m.outletId);
        let selectedOutlets = this.selectedOutlet && this.selectedOutlet?.length > 0 ? this.selectedOutlet : this.outlets;
        let selectedOutletsWithoutCommissions = selectedOutlets?.filter(f => !existingOutletsWithCommissions?.includes(f.subPropertyID));
        
        if (outlet && outlet.length > 0) {
          if(!(this.business?.commissionGridData && this.business?.commissionGridData?.length > 0)){
            this.outlets = outlet.map(x => { return { id: x.id, subPropertyID: x.id, subPropertyName: x.name, subPropertyCode: '', propertyID: 0, isActive: true } });
            this.GetCommissionDataForItemSetup();
          }
          else{
            this.outlets = outlet.map(x => { return { id: x.id, subPropertyID: x.id, subPropertyName: x.name, subPropertyCode: '', propertyID: 0, isActive: true } });
            this.AddDefaultCommissionForNewlyAddedOutlets();
          }
        }
      });
      this.retailSetupService.retailItemUpdateCommissionsubscription = this.retailSetupService.retailItemUpdateEvent.pipe(takeUntil(this.destroyed$)).subscribe(itemId => {
        this.UpdateCommissionForItemSetup(itemId);
      });

      this.retailSetupService.retailItemDescChangeEvent.pipe(takeUntil(this.destroyed$)).subscribe(itemDesc => {
        this.UpdateItemDescForItemSetup(itemDesc);
      });
    }
    else {
      this.BindGridData();
      this.GetCommissionData();
    }


    this.commissionsChanges = this.FormGrp.valueChanges.subscribe(data => {
      this.retailSetupService.ValidateUpdate();
    });
    [this.initialLoads, this.callCounter] = this.business.updateInitalLoads(false, this.initialLoads, this.callCounter);
    this.relativePlacehoder = this.captions.Value;
    this.enableAdvSearch = (this.fromScreen !== 'THERAPIST');


    this.validateBtnSubscription = this.retailSetupService.validateBtn.subscribe(isValid =>
      this.validateBtn = isValid
    );
  }

  async GetCommissionDataForItemSetup() {
    this.selectedOutlet = _.cloneDeep(this.outlets);
    this.business.commissionGridData = [];
    let itemId = 0;
    if (this.isEdit) {
      itemId = this.retailSetupService.originalItemDetail.retailItemDetail.id;
      await this.GetCommissionSetupData();
    } else {
      this.BuildGridDataForItemSetup();
    }
  }

  BuildGridDataForItemSetup() {
    this.business.commissionGridData = [];
    let selectedOutlets = this.selectedOutlet && this.selectedOutlet.length > 0 ? this.selectedOutlet : this.outlets;
    for (var outlet of selectedOutlets) {
      let gridData: CommissionDetailsUI = {
        id: 0,
        itemId: this.isEdit ? this.retailSetupService.originalItemDetail.retailItemDetail.id : 0,
        classId: this.FormGrp.controls.ClassType.value,
        itemDescription: this.retailSetupService.GeneralFormGrp.value.itemdescription,
        isAfterDiscount: false,
        isItemOnly: true,
        flatAmount: "0.00",
        threshold: "0.00",
        outletId: outlet.subPropertyID,
        locationDescription: outlet.subPropertyName,
        categoryId: this.retailSetupService.GeneralFormGrp.value.categoryid,
        commissionon: this.captions.Items,
        percentage: "0.00",
        isTotalRevenue: false,
        staffType: null,
        staffId: 0,
        checked: this.isAllItemsSelected,
        isModified: true
      };
      this.business.commissionGridData.push(gridData);
    }
    this.BindGridData();
  }

  AddDefaultCommissionForNewlyAddedOutlets() {
    let existingOutletsWithCommissions = this.business?.commissionGridData?.filter(f => f.outletId).map(m => m.outletId);
    let selectedOutletsWithoutCommissions = this.outlets?.filter(f => !existingOutletsWithCommissions?.includes(f.subPropertyID));
    if(selectedOutletsWithoutCommissions && selectedOutletsWithoutCommissions?.length > 0){
      for (var outlet of selectedOutletsWithoutCommissions) {
        let gridData: CommissionDetailsUI = {
          id: 0,
          itemId: this.isEdit ? this.retailSetupService.originalItemDetail.retailItemDetail.id : 0,
          classId: this.FormGrp.controls.ClassType.value,
          itemDescription: this.retailSetupService.GeneralFormGrp.value.itemdescription,
          isAfterDiscount: false,
          isItemOnly: true,
          flatAmount: "0.00",
          threshold: "0.00",
          outletId: outlet.subPropertyID,
          locationDescription: outlet.subPropertyName,
          categoryId: this.retailSetupService.GeneralFormGrp.value.categoryid,
          commissionon: this.captions.Items,
          percentage: "0.00",
          isTotalRevenue: false,
          staffType: null,
          staffId: 0,
          checked: this.isAllItemsSelected,
          isModified: true
        };
        this.business.commissionGridData.push(gridData);
      }
      this.BindGridData();
    }
  }


  UpdateItemDescForItemSetup(itemDesc: string) {
    if (this.commissionGridData && this.commissionGridData.length > 0) {
      this.commissionGridData.forEach(r => r.itemDescription = itemDesc ? itemDesc : '');
      this.BindGridData(this.commissionGridData, true);
    }
  }

  async GetAllCategories() {
    this.CommissioncategoryArr = await this.business.GetAllCategories();
    this.CommissioncategoryArr.forEach(r => { r.description = r.name; r.showInDropDown = true; });
  }

  ngAfterViewInit() {
    this.clearCurrencyDirectiveDefaultValue();
  }

  async GetCommissionData() {
    await Promise.all([this.GetAllCategories(), this.GetUserOutlets()]);
    if (!this.fromScreen && this.business.commissionGridData.length > 0) {
      // Retail the page data if it's from therapist setup and data are already loaded.
    }
    else {
      this.GetCommissionSetupData();
    }
  }

  async GetUserOutlets() {
    this.outlets = await this.business.GetActiveOutletsByUserAccess();
  }

  async BuildCommissionDataByClass(classId: number) {
    let selectedOutletIds = this.selectedOutlet.map(r => r.subPropertyID);
    if (selectedOutletIds.length == 0) {
      selectedOutletIds = this.outlets.map(r => r.subPropertyID);
    }
    let commissionData = [];
    commissionData = await this.business.GetCommissionData(classId, selectedOutletIds);
    if (commissionData && commissionData.length == 2) {
      this.CommissionItemArr = commissionData[0];
      let allCommissionConfig: Commission[] = commissionData[1];
      if (allCommissionConfig && allCommissionConfig.length > 0) {
        allCommissionConfig = allCommissionConfig.filter(r => selectedOutletIds.includes(r.outletId));
      }
    }
  }

  OnCommissionTypeChange(e) {
    let currencyCode = this.SelectedCommissionType == 'Flat Amount' ? `(${this.localization.currencySymbol})` : '';
    this.relativePlacehoder = `${this.captions.Enter} ${e.value}${currencyCode}`;
    this.commissionMaxLength = this.SelectedCommissionType == 'Percentage' ? Maxlength.PERCENTAGE : Maxlength.FLATAMOUNT;
    if (this.SelectedCommissionType == 'Percentage' || this.SelectedCommissionType == 'Threshold' || this.SelectedCommissionType == 'Flat Amount') {
      this.isDisabled = 'false';
    }
    this.FormGrp.controls['CommissionValue'].setValue('');
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.fromScreen != 'THERAPIST')
      this.business.commissionGridData = [];
    this.business.negativeNumber = 0;
    this.destroyed$.next(true);
    this.destroyed$.complete();
    this.validateBtnSubscription.unsubscribe();
    this.commissionsChanges.unsubscribe();
  }
  categoryOpenDialog(e, ArrType): void {
    console.log(e);
    const d = document.getElementById(ArrType);
    let topPos = d.offsetTop + 359;
    const leftPos = d.offsetLeft + 15;
    if (ArrType === 'Category') {
      this.DataArray = this.CommissioncategoryArr;
      this.selectedArr = this.selectedcategoryArr;
      this.DataType = 'commissioncategory';
      this.DataArray.forEach(element => {
        if (typeof element === 'object') {
          element['description'] = element.name;
        }
      });
      topPos = d.offsetTop + 174;
    }
    if (ArrType == 'item') {
      this.DataArray = this.CommissionItemArr;
      this.selectedArr = this.itemFilteredArr;
      this.DataType = 'commissionitem';
    }
    const selectedArr = this.selectedArr.map(x => x.id);
    this.filterOptions = this.utils.getFilterPopUpOption(true, this.DataArray, selectedArr, topPos, leftPos, ArrType, 'CoS');
  }

  toggleCommLoc(outletId: any) {
    this.itemFilteredArr = [];
    this.selectedItemArr = [];
    this.selectedOutlet = this.utils.getOutletFilterByObject(this.outlets, this.selectedOutlet, outletId, 'subPropertyID');
    if (this._ViewMoreServiceService.VM_displayData) {
      this._ViewMoreServiceService.VM_displayData.selecteddisplaydataArr = this.GetSelectedOutletMgrArrId(this.selectedOutlet);
    }
    this.HandleChangeEvents();
  }

  updateSelectedValue(filtered, selected, source, option) {
    if (option.id === 0) {
      if (filtered.length === source.length) {
        filtered = [];
      } else {
        filtered = [...source];
      }
    } else if (selected.some(x => x.id === option.id)) {
      filtered = filtered.filter(r => r.id !== option.id && r.id !== 0);
    } else {
      filtered.push(option);
      if (filtered.length === source.length - 1) {
        filtered = [...source];
      }
    }
    selected = filtered.length > 0 ? [...filtered] : [];
    return selected;
  }

  isExist(coll, obj, column) {
    let index = -1;
    if (coll) {
      index = coll.findIndex(x => { return ((x[column] || x[column] == 0) ? x[column] : x) == obj[column] });
    }
    return index;
  }

  getToggleFilter(DataArr, SelectedArr, gv, isWeekLocalized = false) {
    if (gv.id == 0 && !isWeekLocalized) {
      if (SelectedArr.indexOf(gv) == -1)
        DataArr.forEach(result => {
          if (SelectedArr.indexOf(result) == -1) {
            SelectedArr.push(result)
          }
        });
      else
        SelectedArr = [];
      return SelectedArr;
    }

    if (SelectedArr.indexOf(gv) == -1) {
      SelectedArr.push(gv);
      if (SelectedArr.length == DataArr.length - 1 && this.isExist(DataArr, { id: 0 }, 'id') != -1) {
        SelectedArr = [];
        DataArr.forEach(result => { SelectedArr.push(result) });
      }
    }
    else {
      SelectedArr.splice(SelectedArr.indexOf(gv), 1);
    }
    return SelectedArr;
  }

  filterCommissions(dataSource?: any[]): CommissionDetails[] {
    let gridData;
    if (dataSource) {
      gridData = dataSource;
    } else {
      gridData = this.commissionGridData;
    }
    let filteredGridData: any = [];
    if (this.itemFilteredArr.length === 0) {
      this.itemFilteredArr = [];
    }
    for (let grid of gridData) {
      const element = grid;
      if (this.itemFilteredArr.length > 0 && !this.itemFilteredArr.some(x => x.id === element.itemId)) {
        continue;
      }
      if (this.categoryFilteredArr.length > 0 && !this.categoryFilteredArr.some(x => x.id === element.categoryId)) {
        continue;
      }
      filteredGridData.push(element);
    }
    if (this.itemFilteredArr.length == 0 && this.categoryFilteredArr.length == 0) {
      filteredGridData = this.commissionGridData;
    }

    //Outlet filter
    if (this.selectedOutlet.length == 1) {
      filteredGridData = filteredGridData.filter(r => r.outletId == this.selectedOutlet[0].subPropertyID);
    }
    else if (this.selectedOutlet.length > 1) {
      let outlets = this.selectedOutlet.map(r => r.subPropertyID);
      let gridFilterByOutlets = [];
      for (var gridRecord of filteredGridData) {
        let itemOutlets: number[] = filteredGridData.filter(r => r.itemId == gridRecord.itemId).map(r => r.outletId);

        if (outlets.some(r => r == gridRecord.outletId)) {
          gridFilterByOutlets.push(gridRecord);
        }
      }
      filteredGridData = gridFilterByOutlets;
    }
    return filteredGridData;
  }

  EditRecords(e) {
    const popupConfiguration: popupConfig = {
      operation: e[1],
      fromScreen: this.fromScreen ? this.fromScreen : 'commission'
    }
    this.OpenDialog(this.localization.captions.common.commission, 'CO', e[0], popupConfiguration);
  }

  async applyall(selectedCommissionType, SelectedCommissionValue) {
    this.isScrollPositionReset = true;
    let commissionApplyType: number = 0;
    let commissionValue: number = 0;
    let threshold: number = 0;
    let commissionOn: number = 0;
    let isCommissionAfterDiscount: boolean = false;
    if (this.checkedCommissionsArr.length == 0) {
      this.business.openErrorDialog(this.captions.PleaseSelectAtleastOneiteminGrid, this.localization.captions.common.Error, this.captions.NoItemSelected);
      return;
    } else if (selectedCommissionType == '') {
      this.business.openErrorDialog(this.captions.PleaseSelectCommissionType, this.localization.captions.common.Error, this.captions.NoItemSelected);
      return;
    } else if (SelectedCommissionValue == '') {
      this.business.openErrorDialog(this.captions.PleaseSelectCommissionValue, this.localization.captions.common.Error, this.captions.NoItemSelected);
      return;
    }
    let IsMatch = false;
    let showCommissionDuplicatePopUp = false;
    switch (selectedCommissionType) {
      case 'Flat Amount': {
        commissionApplyType = CommissionSetupType.FlatAmount;
        commissionValue = this.localization.currencyToSQLFormat(SelectedCommissionValue);
        for (let i = 0; i < this.checkedCommissionsArr.length; i++) {

          if (!IsMatch) {
            this.checkedCommissionsArr[i].flatAmount = this.localization.currencyToSQLFormat(SelectedCommissionValue);
            this.checkedCommissionsArr[i].percentage = 0;
            this.checkedCommissionsArr[i].isModified = true;
          }
        }
      }
        break;
      case 'Threshold': {
        commissionApplyType = CommissionSetupType.Thershold;
        commissionValue = this.localization.currencyToSQLFormat(SelectedCommissionValue);
        threshold = this.localization.currencyToSQLFormat(SelectedCommissionValue);
        for (let i = 0; i < this.checkedCommissionsArr.length; i++) {
          this.CommissionArr = this.fromScreen != RETAILSETUP ? this.commissionService.commissionGridData.filter(x => x.itemId ===
            this.checkedCommissionsArr[i].itemId && Number(x.threshold) === Number(threshold)) : this.commissionService.commissionGridData.filter(x => Number(x.threshold) === Number(threshold));
          IsMatch = this.CommissionArr.some(x => x.classId === this.checkedCommissionsArr[i].classId &&
            x.outletId === this.checkedCommissionsArr[i].outletId);
          showCommissionDuplicatePopUp = IsMatch ? true : showCommissionDuplicatePopUp;

          if (!IsMatch) {
            this.checkedCommissionsArr[i].threshold = this.localization.currencyToSQLFormat(SelectedCommissionValue);
            this.checkedCommissionsArr[i].isModified = true;
          }
        }
      }
        break;
      case 'Percentage': {
        commissionApplyType = CommissionSetupType.Percentage;
        commissionValue = this.localization.currencyToSQLFormat(SelectedCommissionValue);
        for (let i = 0; i < this.checkedCommissionsArr.length; i++) {

          if (!IsMatch) {
            this.checkedCommissionsArr[i].percentage = this.localization.currencyToSQLFormat(SelectedCommissionValue);
            this.checkedCommissionsArr[i].flatAmount = 0;
            this.checkedCommissionsArr[i].isModified = true;
          }

        }
      }
        break;
      case 'CommissionOn': {
        commissionApplyType = CommissionSetupType.CommissionOn;
        commissionOn = SelectedCommissionValue === 'Items' ? CommissionOn.Items : CommissionOn.Revenue;
        for (let i = 0; i < this.checkedCommissionsArr.length; i++) {

          if (!IsMatch) {
            this.checkedCommissionsArr[i].commissionon = SelectedCommissionValue;
            this.checkedCommissionsArr[i].isModified = true;
          }
        }
      }
        break;
      case 'AfterDiscount': {
        commissionApplyType = CommissionSetupType.AfterDiscount;
        isCommissionAfterDiscount = SelectedCommissionValue === this.captions.Yes ? true : false;
        for (let i = 0; i < this.checkedCommissionsArr.length; i++) {
          this.checkedCommissionsArr[i].isAfterDiscount = SelectedCommissionValue == this.captions.Yes ? true : false;
          this.checkedCommissionsArr[i].isModified = true;
        }
      }
        break;
    }
    if (showCommissionDuplicatePopUp) {
      this.openAlertPopup(this.localization.captions.common.duplicateCommissionRecord, this.localization.captions.common.OK, false);
    }
    if (!this.fromScreen || (this.fromScreen && this.fromScreen != 'THERAPIST' && this.fromScreen != RETAILSETUP)) {
      if (this.isAllItemsSelected && commissionApplyType > 0) {
        if (selectedCommissionType == 'Threshold') {
          this.isAllCommissionThresholdModified = true;
          this.updateAllCommissionThersholdValue = SelectedCommissionValue;
        }
        if (selectedCommissionType == 'CommissionOn') {
          this.isAllCommissionOnModified = true;
          this.updateAllCommissionOnValue = SelectedCommissionValue;
        }
        if (selectedCommissionType == 'AfterDiscount') {
          this.isAllCommissionAfterDiscountModified = true;
          this.updateAllCommissionAfterDiscountValue = SelectedCommissionValue;
        }
        if (selectedCommissionType == 'Percentage' || selectedCommissionType == 'Flat Amount') {
          this.isAllCommissionValueModified = true;
          this.updateAllCommissionValue = SelectedCommissionValue;
          this.applyAllCommissionType = commissionApplyType;
        }
        await this.UpdateCommissionSetUpByFilter(commissionApplyType, commissionValue, threshold, this.isAllCommissionThresholdModified, commissionOn, this.isAllCommissionOnModified, isCommissionAfterDiscount, this.isAllCommissionAfterDiscountModified, IsMatch);
      }
      else {
        this.UpdateCommissions(this.checkedCommissionsArr);
      }
      this.RefreshCommissionGrid();
    }
    else {
      if (this.isAllItemsSelected && commissionApplyType > 0) {
        if (selectedCommissionType == 'Threshold') {
          this.SetUpdateAllCommissionFlag(true, false, false, false);
          this.updateAllCommissionThersholdValue = SelectedCommissionValue;
        }
        if (selectedCommissionType == 'CommissionOn') {
          this.SetUpdateAllCommissionFlag(false, true, false, false);
          this.updateAllCommissionOnValue = SelectedCommissionValue;
        }
        if (selectedCommissionType == 'AfterDiscount') {
          this.SetUpdateAllCommissionFlag(false, false, true, false);
          this.updateAllCommissionAfterDiscountValue = SelectedCommissionValue;
        }
        if (selectedCommissionType == 'Percentage' || selectedCommissionType == 'Flat Amount') {
          this.SetUpdateAllCommissionFlag(false, false, false, true);
          this.updateAllCommissionValue = SelectedCommissionValue;
          this.applyAllCommissionType = commissionApplyType;
        }
        await this.UpdateCommissionSetUpByFilter(commissionApplyType, commissionValue, threshold, this.isAllCommissionThresholdModified, commissionOn, this.isAllCommissionOnModified, isCommissionAfterDiscount, this.isAllCommissionAfterDiscountModified, IsMatch);
        if (!this.fromScreen) {
          this.apiCycle = 0;
          this.checkedCommissionsArr = [];
        }
      }
      
      this.isCommissionModified.emit(true);
    }
    this.retailSetupService.ValidateUpdate();
  }
  SetUpdateAllCommissionFlag(isThershold: boolean, isCommissionOn: boolean, isDiscountModified: boolean, isCommissionValue: boolean) {
    this.isAllCommissionValueModified = isCommissionValue;
    this.isAllCommissionThresholdModified = isThershold;
    this.isAllCommissionOnModified = isCommissionOn;
    this.isAllCommissionAfterDiscountModified = isDiscountModified;
  }
  UpdateCommissionForItemSetup(itemId: number) {
    if (!itemId)
      return;
    if (this.business.commissionGridData && this.business.commissionGridData.length > 0) {
      this.business.commissionGridData = this.business.commissionGridData.filter(x => x.isModified);
    }
    if (this.business.commissionGridData && this.business.commissionGridData.length > 0) {
      if (!this.isEdit) {
        this.business.commissionGridData.forEach(r => r.itemId = itemId);
      }
      this.UpdateCommissions(this.business.commissionGridData);
    }
    else if (this.isEdit) { // remove all existing commisions configured for an Item if required

    }

  }

  RefreshCommissionGrid(){        
    this.apiCycle = 0;
    this.checkedCommissionsArr = [];
    this.isAllItemsSelected = false;
    this.business.commissionGridData.forEach(r => r.checked = false);
    this.GetCommissionSetupData();
  }

  UpdateCommissions(commissionDataArr: CommissionDetailsUI[]) {
    this.utils.ToggleLoader(true, this.captions.lbl_processing);
    let bodyObj: CommissionDetails;
    const bodyObjArr: CommissionDetails[] = [];
    for (let i = 0; i < commissionDataArr.length; i++) {
      const commissionData = commissionDataArr[i];
      bodyObj = {
        id: commissionData.id,
        itemId: commissionData.itemId,
        categoryId: commissionData.categoryId,
        itemDescription: commissionData.itemDescription,
        locationDescription: commissionData.locationDescription,
        classId: this.FormGrp.controls['ClassType'].value,
        flatAmount: commissionData.flatAmount,
        percentage: commissionData.percentage,
        threshold: commissionData.threshold,
        isAfterDiscount: commissionData.isAfterDiscount,
        isTotalRevenue: commissionData.commissionon == this.captions.Revenue ? true : false,
        isItemOnly: commissionData.commissionon == this.captions.Items ? true : false,
        productId: this.retailSetupService.ProductId,
        outletId: commissionData.outletId,
        staffType: null,
        staffId: 0
      }
      bodyObjArr.push(bodyObj);
    }
    this.http.CallApiWithCallback<any[]>({
      host: Host.commission,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: 'UpdateCommission',
      uriParams: { classId: bodyObj.classId },
      method: HttpMethod.Put,
      body: bodyObjArr,
      showError: true,
      extraParams: []
    });
  }

  OpenDialog(title, templateName, e, popupConfiguration) {
    const Dialogtitle = title;
    const data = e;
    const dialogRef = this.dialog.open(SettingDialogPopupComponent, {
      height: '66%',
      maxHeight: '635px',
      width: '661px',
      data: { headername: Dialogtitle, closebool: true, templatename: templateName, datarecord: data, popupConfig: popupConfiguration },
      panelClass: 'small-popup',
      disableClose: true,
      hasBackdrop: true
    });
    this.subscription = dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.BindGridData();
        this.isCommissionModified.emit(true);
        this.retailSetupService.ValidateUpdate();
      }
    });
  }

  OnClassChange(event: any) {
    this.HandleChangeEvents();
  }

  HandleChangeEvents() {
    if (this.fromScreen === RETAILSETUP) {
      if (this.isEdit) {
        this.SearchCommission();
      }
      else {
        this.BuildGridDataForItemSetup();
      }
    }
  }

  RowSelected(e: any) {
    if (e.length == this.tableoptions[0].TablebodyData.length) {
      this.applyBtn = this.localization.captions.setting.ApplyAll;
      this.isAllItemsSelected = true;
      e.map(r => r.checked = true);
      this.business.commissionGridData = e;
    } else {
      this.applyBtn = this.localization.captions.setting.Apply;
      this.isAllItemsSelected = false;
      // Update selected record in base colection
      if (e && e.length > 0 && this.business.commissionGridData && this.business.commissionGridData.length > 0) {
        e.forEach(element => {
          var baseRecord = this.business.commissionGridData.find(r => r.recordSequence == element.recordSequence);
          if (baseRecord) {
            element.checked = true;
            baseRecord.checked = true;
          }
        });
      }
    }
    this.checkedCommissionsArr = e;
  }

  async LoadCommissionableRetailItems() {
    [this.initialLoads, this.callCounter] = this.business.updateInitalLoads(false, this.initialLoads, this.callCounter);
    const response = await this.http.CallApiAsync<CommissionableRetailItem[]>({
      host: Host.retailManagement,
      callDesc: 'GetCommissionItems',
      method: HttpMethod.Get,
      uriParams: {},
    });
    [this.initialLoads, this.callCounter] = this.business.updateInitalLoads(true, this.initialLoads, this.callCounter);
    if (response && response.result) {
      for (let i = 0; i < response.result.length; i++) {
        this.CommissionItemArr.push(response.result[i]);
      }
    }
  }

  makeGetCall(routeURL: string) {
    this.http.CallApiWithCallback<any[]>({
      host: Host.retailManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: routeURL,
      method: HttpMethod.Get,
      showError: false,
      extraParams: ['dataBelongTo']
    });
  }

  virtualScrollHandler(arg) {
    if (this.fromScreen == RETAILSETUP) {
      return;
    }
    if (arg.viewPortItems && arg.viewPortItems.length && !this.isLoading) {
      console.log(arg.searchText);
      console.log(arg.scrollArg.endIndex);
      console.log(arg.viewPortItems[0]);
      console.log(arg.viewPortItems[arg.viewPortItems.length - 1]);
      this.isScrollPositionReset = false;
    }
    this.GetCommissionSetupData();
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams?: any[]) {
    const results = <any>result.result;
    if (callDesc == "UpdateCommission") {
      this.checkedCommissionsArr = [];
      this.categoryFilteredArr = [];
      this.isAllItemsSelected = false;
      this.business.commissionGridData.forEach(r => r.checked = false);
      this.BindGridData();
      this.utils.ToggleLoader(false);
    }
  }

  BindGridData(DataSource?: any, IsFiltered?: boolean) {
    let tableHeader = this.business.getDataheader(GridType.commission);
    if (!this.showOutletInGrid) {
      tableHeader = tableHeader.filter(x => x.title !== this.localization.captions.setting.Location);
    }
    this.tableoptions = [{
      TableHdrData: tableHeader,
      TablebodyData: this.business.commissionGridData,
      pagination: false,
      sortable: false,
      CustomColumn: true,
      PlaceHoldertext: 'Description, Location, Thershold, Flat Amount, Commission On',
      EnableActions: false,
      SelectRows: true,
      IsCommission: true,
      SelectedSettingId: GridType.commission,
      Searchable: false,
      EditMoreOption: true,
      Sortable: 'itemDescription',
      TableId: GridType.commission,
      IsReadOnly: this.IsReadOnly,
      IsMoreOptionReadOnly: this.IsReadOnly,
      disableDelete: true,
      TableDraggable: false,
      bufferCount: 10
    }];
  }

  errorCallback(callDesc: string): void {
    console.error('error');
    this.utils.ToggleLoader(false)
  }

  bindLocations(event) {
    this.noOfLocations = event;
  }

  emitedValue(event) {
    this.toggleCommLoc(event[2]);
  }

  close() {
    this.closeRetailDialog.emit(true);
  }

  MoreData(event, outlets, selectedOutlet, noOfLocations, section, dispArrKey) {
    this._ViewMoreServiceService.setdata(event, outlets, selectedOutlet.map(x => x.subPropertyID), noOfLocations, section, dispArrKey);
  }

  OnSortByChange(event) {
    switch (event.value) {
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        this.sortOrder = [{ id: 1, description: this.captions.Ascending },
        { id: 2, description: this.captions.Descending }];
        break;
      case 6:
        this.sortOrder = [{ id: 3, description: this.captions.CommissionOnItem },
        { id: 4, description: this.captions.CommissionOnRevenue }];
        break;
      case 7:
        this.sortOrder = [{ id: 5, description: this.captions.AfterDiscount },
        { id: 6, description: this.captions.WithDiscount }];
        break;
    }
  }

  async SearchCommission() {
    this.tableoptions[0].TablebodyData = [];
    this.isScrollPositionReset = true;
    this.checkedCommissionsArr = [];
    this.isAllItemsSelected = false;
    this.apiCycle = 0;
    this.GetCommissionSetupData();
  }

  BuidGridData(response: CommissionSetupResponse[]) {
    if (this.apiCycle == 0) {
      this.business.commissionGridData = [];
      this.BindGridData();
    }
    if (!response || response.length == 0) {
      if (this.isEdit) {
        this.BuildGridDataForItemSetup();
      }
      return;
    }
    let counter = this.business.commissionGridData ? this.business.commissionGridData.length : 0;
    for (var r of response) {
      if (this.business.commissionGridData.some(x => x.id == r.id))
        continue;
      let gridData: CommissionDetailsUI = {
        id: r.id,
        itemId: r.itemId,
        classId: r.classId,
        itemDescription: r.description,
        isAfterDiscount: r.isCommissionAfterDiscount,
        isItemOnly: r.isItemOnly,
        flatAmount: r.flatAmount,
        threshold: r.threshold,
        outletId: r.outletId,
        locationDescription: r.outletName,
        categoryId: r.categoryId,
        commissionon: r.isItemOnly ? this.captions.Items : this.captions.Revenue,
        percentage: r.percentage,
        isTotalRevenue: !r.isItemOnly,
        staffType: r.staffType,
        staffId: r.staffId,
        checked: this.isAllItemsSelected,
        isModified: false,
        recordSequence: ++counter
      };
      if (this.fromScreen == 'THERAPIST' || this.fromScreen == RETAILSETUP) {
        gridData = this.checkAndSetUpdateAllValueOnLazyLoad(gridData);
      }
      if (this.isAllItemsSelected) {
        this.checkedCommissionsArr.push(gridData); // this.checkedCommissionsArr && this.business.commissionGridData both are refrenced in RowSelected(). pushing into checkedCommissionsArr will reflect in this.business.commissionGridData also
      }
      else {
        this.business.commissionGridData.push(gridData);
      }
    }
    this.apiCycle += 1;
    this.BindGridData();
    this.utils.ToggleLoader(false)
  }

  async GetCommissionSetupData() {
    let outletIds: number[] = [];
    if (this.selectedOutlet && this.selectedOutlet.length > 0) {
      outletIds = this.selectedOutlet.map(x => x.subPropertyID);
    }
    else {
      outletIds = this.outlets.map(x => x.subPropertyID);
    }
    if (!this.showOutletsSelection) {
      const tempItem = outletIds.length > 0 ? [outletIds[0]] : outletIds;
      outletIds = this.defaultOutlet > 0 ? [this.defaultOutlet] : tempItem;
    }

    try {
      this.isLoading = true;
      let body: CommissionSetupRequest = {
        recordsToSkip: (this.apiCycle * this.recordsPerAPICall),
        recordsToFetch: this.recordsPerAPICall,
        classId: this.FormGrp.controls.ClassType.value ? this.FormGrp.controls.ClassType.value : 0,
        outletId: outletIds,
        categoryId: this.FormGrp.controls.retailCategory.value.length ? this.FormGrp.controls.retailCategory.value.map(r => r.id) : [],
        searchText: this.FormGrp.controls.itemDescription.value,
        threshold: this.FormGrp.controls.threshold.value ? this.localization.currencyToSQLFormat(this.FormGrp.controls.threshold.value) : null,
        flatAmount: this.FormGrp.controls.flatAmount.value ? this.localization.currencyToSQLFormat(this.FormGrp.controls.flatAmount.value) : null,
        percentage: this.FormGrp.controls.percentage.value ? this.localization.currencyToSQLFormat(this.FormGrp.controls.percentage.value) : null,
        commissionOn: this.FormGrp.controls.commissionOn.value ? this.FormGrp.controls.commissionOn.value : 0,
        afterDiscount: this.FormGrp.controls.afterDiscount.value ? this.FormGrp.controls.afterDiscount.value : 0,
        sortBy: this.FormGrp.controls.sortBy.value ? this.FormGrp.controls.sortBy.value : 0,
        sortOrder: this.FormGrp.controls.sortOrder.value ? this.FormGrp.controls.sortOrder.value : 0,
        staffId: this.staffId,
        staffType: this.fromScreen == 'THERAPIST' ? 'THERAPIST' : null,
        itemId: this.fromScreen == RETAILSETUP ? [this.retailSetupService.originalItemDetail.retailItemDetail.id] : null,
        onlyCommissionable: this.fromScreen == RETAILSETUP ? false : true
      };
      let response = await this.business.getCommissionSetup(body);
      this.commissionSetupUpdateAllRequest = body;
      this.BuidGridData(response);
      this.isLoading = false;
    } catch (ex) {
      console.error(ex);
    } finally {
      this.isLoading = false;
    }
  }

  async UpdateCommissionSetUpByFilter(commissionApplyType: number, commissionValue: number, threshold: number, isThresholdModified: boolean, commissionOn: number, isCommissionOnModified: boolean, isCommissionAfterDiscount: boolean, isCommissionAfterDiscountModified: boolean, isUIMatchFound = false) {
    let outletIds: number[] = [];
    let updateCommissionSetupValue: UpdateCommissionSetupValue = {
      commissionType: commissionApplyType,
      commissionValue: commissionValue,
      threshold: threshold,
      isThresholdModified: isThresholdModified,
      commissionOn: commissionOn,
      isAllCommissionValueModified: this.isAllCommissionValueModified,
      isCommissionOnModified: isCommissionOnModified,
      isCommissionAfterDiscount: isCommissionAfterDiscount,
      isCommissionAfterDiscountModified: isCommissionAfterDiscountModified,
      staffId: 0,
      applyAllCommissionType: this.applyAllCommissionType,
      updateAllCommissionValue: this.updateAllCommissionValue,
      updateAllCommissionThersholdValue: this.updateAllCommissionThersholdValue,
      updateAllCommissionOnValue: this.updateAllCommissionOnValue,
      updateAllCommissionAfterDiscountValue: this.updateAllCommissionAfterDiscountValue
    };

    this.utils.ToggleLoader(true,this.captions.lbl_processing);
    if (this.selectedOutlet && this.selectedOutlet.length > 0) {
      outletIds = this.selectedOutlet.map(x => x.subPropertyID);
    }
    else {
      outletIds = this.outlets.map(x => x.subPropertyID);
    }
    if (!this.showOutletsSelection) {
      const tempItem = outletIds.length > 0 ? [outletIds[0]] : outletIds;
      outletIds = this.defaultOutlet > 0 ? [this.defaultOutlet] : tempItem;
    }

    try {
      this.isLoading = true;
      if (this.fromScreen != 'THERAPIST' && this.fromScreen != RETAILSETUP) {
        this.commissionSetupUpdateAllRequest.recordsToSkip = 0;
        this.commissionSetupUpdateAllRequest.recordsToFetch = -1;
        this.commissionSetupUpdateAllRequest = { ...this.commissionSetupUpdateAllRequest };
        let response = await this.business.UpdateRetailCommissionSetUpByFilter(this.commissionSetupUpdateAllRequest, updateCommissionSetupValue, this.mapCommission(this.checkedCommissionsArr));
        if (response.isDuplicatesFoundWhileUpdate && !isUIMatchFound) {
          this.openAlertPopup(this.localization.captions.common.duplicateCommissionRecord, this.localization.captions.common.OK, false);
        }
      }
      else {
        if (this.fromScreen == RETAILSETUP) {
          this.commissionSetupUpdateAllRequest = {} as CommissionSetupRequest;
        }
        else {
          this.commissionSetupUpdateAllRequest.recordsToSkip = 0;
          this.commissionSetupUpdateAllRequest.recordsToFetch = -1;
          this.commissionSetupUpdateAllRequest = { ...this.commissionSetupUpdateAllRequest };
        }
        this.modifiedCommissionValue.emit({ commissionSetupRequest: this.commissionSetupUpdateAllRequest, updateCommissionSetupValue: updateCommissionSetupValue });
      }
      this.isLoading = false;
      this.utils.ToggleLoader(false);
    } catch (ex) {
      console.error(ex);
    } finally {
      this.isLoading = false;
    }
  }


  getFormControlValue(event) {
    if (event[0] === 'retailCategory') {
      this.FormGrp.setControl('retailCategory', event[1]);
    }
  }

  closeAdvanceSearch() {
    this.enableAdvSearch = false;
  }

  toggleAdvancedSearch() {
    this.enableAdvSearch = true;
    this.SearchCommission();
    setTimeout(() => this.clearCurrencyDirectiveDefaultValue(), 1);
  }
  cancelSearchFilters() {
    this.FormGrp = this.Form.group({
      ClassType: this.fromScreen == 'THERAPIST' ? '0' : '1',
      CommissionPayable: '',
      CommissionType: '',
      CommissionValue: '',
      selectedCC: '',
      Threshold: '',
      listOrder: '',
      itemDescription: '',
      threshold: '',
      flatAmount: '',
      percentage: '',
      commissionOn: '',
      afterDiscount: '',
      retailCategory: '',
      sortBy: '',
      sortOrder: ''
    });
    this.CommissioncategoryArr = [... this.CommissioncategoryArr];
    this.selectedOutlet = [];
  }

  clearCurrencyDirectiveDefaultValue() {
    if (!this.FormGrp.controls['threshold'].value || this.FormGrp.controls['threshold'].value === this.localization.localizeCurrency(0, false)) this.FormGrp.controls['threshold'].setValue('');
    if (!this.FormGrp.controls['flatAmount'].value || this.FormGrp.controls['flatAmount'].value === this.localization.localizeCurrency(0, false)) this.FormGrp.controls['flatAmount'].setValue('');
    if (!this.FormGrp.controls['percentage'].value || this.FormGrp.controls['percentage'].value === this.localization.localizeCurrency(0, false)) this.FormGrp.controls['percentage'].setValue('');
  }

  GetSelectedOutletMgrArrId(array) {
    if (array && array.length > 0) {
      return array.map(x => x.subPropertyID);
    }
    return [];
  }

  async save() {

    this.retailSetupService.ValidateUpdate();
    if (!this.validateBtn) return;
    if (!this.isEdit) {
      this.validateBtn = false;
    }
    let updateBody = this.retailSetupService.FormBodyData(this.isEdit, this.PropertyInfo.UseRetailInterface);
    let response;

    let itemId = 0;
    if (this.isEdit) {
      response = await this.retailSetupService.InvokeServiceCallAsync("UpdateRetailItem", Host.retailManagement, HttpMethod.Put, updateBody, { id: updateBody.retailItem.retailItemDetail.id })
      if (response) {
        this.retailSetupService.updateRetailItemImage(updateBody.retailItem.retailItemDetail.id);
        itemId = updateBody.retailItem.retailItemDetail.id;
      }
    }
    else {
      response = await this.retailSetupService.InvokeServiceCallAsync("CreateRetailItem", Host.retailManagement, HttpMethod.Post, updateBody)
      if (response) {
        this.retailSetupService.saveImage(response.result)
        itemId = response.result;
      }
    }

    if (response && response.result) {
      this.retailSetupService.retailItemUpdateEvent.next(itemId);
      this.dialogRef.close(updateBody);
    }
  }
  openAlertPopup(headerMessage: string, buttonName: string, noButton: boolean) {
    return this.dialog.open(AlertMessagePopupComponent, {
      width: '305px',
      height: '350px',
      hasBackdrop: true,
      panelClass: 'small-popup',
      data: {
        headername: this.localization.captions.common.Warning, headerIcon: 'icon-warning-icon',
        headerMessage: headerMessage, buttonName: buttonName, noButton: noButton, noButtonName: this.localization.captions.common.No, type: 'message'
      },
      disableClose: true,
    });
  }
  mapCommission(commissionDataArr: CommissionDetailsUI[]): CommissionDetails[] {
    let bodyObj: CommissionDetails;
    const bodyObjArr: CommissionDetails[] = [];
    for (let i = 0; i < commissionDataArr.length; i++) {
      const commissionData = commissionDataArr[i];
      bodyObj = {
        id: commissionData.id,
        itemId: commissionData.itemId,
        categoryId: commissionData.categoryId,
        itemDescription: commissionData.itemDescription,
        locationDescription: commissionData.locationDescription,
        classId: this.FormGrp.controls['ClassType'].value,
        flatAmount: commissionData.flatAmount,
        percentage: commissionData.percentage,
        threshold: commissionData.threshold,
        isAfterDiscount: commissionData.isAfterDiscount,
        isTotalRevenue: commissionData.commissionon == this.captions.Revenue ? true : false,
        isItemOnly: commissionData.commissionon == this.captions.Items ? true : false,
        productId: this.retailSetupService.ProductId,
        outletId: commissionData.outletId,
        staffType: null,
        staffId: 0
      }
      bodyObjArr.push(bodyObj);
    }
    return bodyObjArr;
  }

  checkAndSetUpdateAllValueOnLazyLoad(lazyData: CommissionDetailsUI): CommissionDetailsUI {
    if (this.isAllCommissionThresholdModified) {
      lazyData.threshold = this.updateAllCommissionThersholdValue;
    }
    if (this.isAllCommissionOnModified) {
      lazyData.commissionon = (this.updateAllCommissionOnValue == CommissionOn.Items) ? 'Items' : 'Revenue';
    }
    if (this.isAllCommissionAfterDiscountModified) {
      lazyData.isAfterDiscount = this.updateAllCommissionAfterDiscountValue;
    }
    if (this.isAllCommissionValueModified) {
      if (this.applyAllCommissionType == CommissionSetupType.Percentage) {
        lazyData.percentage = this.updateAllCommissionValue;
        lazyData.flatAmount = 0;
      }
      else if (this.applyAllCommissionType == CommissionSetupType.FlatAmount) {
        lazyData.percentage = 0;
        lazyData.flatAmount = this.updateAllCommissionValue;
      }
    }
    return lazyData;
  }

}



