import { Component, OnInit, ViewChild, Inject, Input, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTabChangeEvent, MatTabGroup, MatTab, MatTabHeader } from '@angular/material/tabs';
import { SpaLocalization } from '../../../core/localization/spa-localization';
import { AppointmentpopupService } from '../../service/appointmentpopup.service';
import { HttpServiceCall, HttpMethod } from '../../service/http-call.service';
import { SpaUtilities } from '../../utilities/spa-utilities';
import * as GlobalConst from '../../globalsContant';
import { BaseResponse } from '../../business/shared.modals';
import { ClientLabel } from '../../business/new-booking.model';
import { AppointmentdetailsComponent } from './appointmentdetails/appointmentdetails.component';
import { DefaultGUID } from 'src/app/retail/shared/globalsContant';
import { GuaranteeMethodBusiness, GuaranteeMethodJSONModel } from './guarantee-method/guarantee-method.business';
import { appointmentService } from '../../service/appointment.service';
import { CartUtilities } from 'src/app/common/components/menu/vcart/cart.utilities';
import { BreakPointAccess } from 'src/app/shared/service/breakpoint.service';
import { SpaPropertyInformation } from 'src/app/core/services/spa-property-information.service';
@Component({
  selector: 'app-create-appointment',
  templateUrl: './create-appointment.component.html',
  styleUrls: ['./create-appointment.component.scss'],
  providers: [GuaranteeMethodBusiness],
  encapsulation: ViewEncapsulation.None
})
export class CreateAppointmentComponent implements OnInit {
  activateClientSelected: any;
  appointmentDetailsUpdate: boolean = false;
  bindLinkingAppointments: boolean;
  selectedTabindex: number = 0;
  previousTabIndex: number = 0;

  clientClick(e: MatTabChangeEvent) {
    this.appointmentservice.selectedIndex = e.index;
  }
  @ViewChild('appointmentTabGroup', { static: true }) tabGroup: MatTabGroup;
  @ViewChild('appointmentdetail') appointmentdetail: AppointmentdetailsComponent;
  captions: any = this.localization.captions.bookAppointment;
  allCaptions: any = this.localization.captions;
  DefaultGUID = DefaultGUID;
  @Input() from: boolean = false;
  cardId: number = 0;
  isEmbed: boolean = false;
  pastDateDisable: boolean = false;
  constructor(public appointmentservice: AppointmentpopupService
    , @Inject(MAT_DIALOG_DATA) private data: any
    , private http: HttpServiceCall
    , private utils: SpaUtilities
    , public localization: SpaLocalization
    , private apptService: appointmentService
    , private guaranteeBusiness: GuaranteeMethodBusiness
    , private cartUtils: CartUtilities
    , private breakPoint: BreakPointAccess
    , private PropertyInfo: SpaPropertyInformation
  ) {
    this.isEmbed = this.cartUtils.isEmbed();
   }

  async ngOnInit() {
    //this.appointmentservice.selectedIndex=1;
    this.tabGroup._handleClick = this.handleTabChange.bind(this);
    await this.SetGuaranteeMethodFlag();
    this.pastDateDisable = this.localization.compareDates(this.localization.getDate(this.appointmentservice?.resultExistingAppointment?.appointmentDetail?.startTime),this.PropertyInfo.CurrentDate);
    this.appointmentservice.isCheckedOutAppointment = this.appointmentservice?.resultExistingAppointment?.appointmentDetail?.status === 'CKOUT' && !this.breakPoint.CheckForAccess([GlobalConst.SPAScheduleBreakPoint.MoveCheckedOutAppointment],false);
  }

 


  handleTabChange(tab: MatTab, tabHeader: MatTabHeader, idx: number) {
    
      if (this.appointmentservice.clientCreditCardInfo && this.appointmentservice.clientCreditCardInfo.length > 0) {
        const activeCard = this.appointmentservice.clientCreditCardInfo.filter(x => x.isActive);
        if (activeCard.length > 0) {
          this.cardId = activeCard[0].tokenTransId;
        }      
    }

    this.appointmentDetailsUpdate = true;
    if (idx === 3 || idx === 4) {
      this.bindLinkingAppointments = !this.bindLinkingAppointments;
    }
    return MatTabGroup.prototype._handleClick.apply(this.tabGroup, arguments);
  }

  ClientAddedEvent(clientId) {
    if (clientId && clientId > 0) {
      this.getClientInformation(clientId);
      this.getRecentAppointments(clientId);
    }
  }

  getRecentAppointments(clientId: number) {
    this.http.CallApiWithCallback<any[]>({
      host: GlobalConst.Host.schedule,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: "getClientRecentAppointmentInfo",
      uriParams: { clientID: clientId },
      method: HttpMethod.Get,
      showError: false,
      extraParams: []
    });
  }

  getClientInformation(clientId: number) {
    this.http.CallApiWithCallback<any[]>({
      host: GlobalConst.Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.utils.errorCallback.bind(this),
      callDesc: "getClientInfo",
      uriParams: { id: clientId },
      method: HttpMethod.Get,
      showError: false,
      extraParams: []
    });
  }

  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "getClientRecentAppointmentInfo") {
      let resp: any = result.result;
      let RecentAppsUIArr = [];
      for (let item of resp) {
        const element = item;
        RecentAppsUIArr.push(this.createRecentAppsObj(element.serviceName, element.therapistNames, element.date, element.status));
      }
      this.appointmentservice.RecentAppsUIArr = RecentAppsUIArr;
    }
    if (callDesc == "getClientInfo") {
      let resp: any = result.result;
      if (!this.appointmentservice.labelRecords) {
        this.appointmentservice.labelRecords = [];
      }
      if (!this.appointmentservice.labelRecords.some(r => r.Id == resp.clientDetail.id)) {
        let clientLable: ClientLabel = {
          Id: resp.clientDetail.id,
          FirstName: resp.clientDetail.firstName,
          LastName: resp.clientDetail.lastName
        }
        this.appointmentservice.labelRecords.push(clientLable);
      }

      if (!this.appointmentservice.recordsArray) {
        this.appointmentservice.recordsArray = [];
      }
      resp.id = resp.clientDetail.id;

      if (this.appointmentservice.recordsArray.map(x => x.clientDetail).map(x => x.id).indexOf(resp.id) == -1) {
        this.appointmentservice.recordsArray.push(resp);
      }

      this.activateClientSelected = resp;
    }

  }

  createRecentAppsObj(ServiceName: string, TherapistNames: string[], onDate: Date, Status: string): any {
    var dt = this.utils.getDate(onDate)
    var dtStr = this.localization.LocalizeShortDateTime(dt);
    let desc: string = ServiceName + " " + this.captions.by + " " + TherapistNames.toString() + " " + this.captions.on + " " + dtStr;
    let UiStatus: string;

    if (Status == "RESV") {
      UiStatus = this.captions.Scheduled
    } else if (Status == "CKIN") {
      UiStatus = this.captions.CheckedIn
    }
    else if (Status == "CKOUT") {
      UiStatus = this.captions.CheckedOut
    }
    else if (Status == "NOSHOW") {
      UiStatus = this.captions.NoShow
    }
    else if (Status == "CANC") {
      UiStatus = this.captions.Cancelled
    }
    return {
      appointmentDetails: desc,
      action: UiStatus
    };
  }

  async SetGuaranteeMethodFlag() {
    this.appointmentservice.isGuaranteeMethodRequired = await this.apptService.IsGuaranteePaymentRequired();
    if (this.appointmentservice.resultExistingAppointment) {
      const existingApptData = this.appointmentservice.resultExistingAppointment;
      this.appointmentservice.guaranteeMethodConfig = {
        bookingStartDate: existingApptData.appointmentDetail.startTime,
        billingDetail: existingApptData.appointmentBillingDetail,
        bookingEndDate: existingApptData.appointmentDetail.startTime,
        isMandatoryOnAppointment: this.appointmentservice.isGuaranteeMethodRequired
      }
    } else {
      this.appointmentservice.guaranteeMethodConfig = {
        bookingStartDate: this.appointmentservice.resultNewAppointment.dateField,
        bookingEndDate: this.appointmentservice.resultNewAppointment.dateField,
        isMandatoryOnAppointment: this.appointmentservice.isGuaranteeMethodRequired,
      }
    }
  }

  GuaranteeMethodChange(data: GuaranteeMethodJSONModel) {
    const enableSave = this.guaranteeBusiness.IsGuaranteeMethodAvailable(data.guaranteePaymentMethod, this.appointmentservice.isGuaranteeMethodRequired);
    this.appointmentservice.isGuaranteeMethodValid = enableSave;
    this.appointmentservice.isGuaranteeMethodDirty = data.isFormDirty;
    this.appointmentservice.guaranteeMethodData = data;
  }

  onTabChange($event: any) {
    if($event.index == 5 && !this.appointmentservice.hasAccessForAddOns) //For Addons Tab
    {
      this.breakPoint.showBreakPointPopup(this.localization.captions.breakpoint[GlobalConst.SPAScheduleBreakPoint.ServiceAddOn]);
      this.selectedTabindex = this.previousTabIndex;
      return;
    } else{
      this.selectedTabindex=$event.index;
      this.previousTabIndex=$event.index;
    }
    this.appointmentservice.selectedIndex = this.selectedTabindex;
  }

 

}
