import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { SpaLocalization } from '../../../core/localization/spa-localization';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormArray, Validators } from '@angular/forms';
import { SystemConfigBusinessService } from '../system-config.business.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { SpaFormAgent } from '../../../shared/spa-form';
import { HttpServiceCall, HttpMethod } from '../../../shared/service/http-call.service';
import { SystemConfiguration } from '../../../shared/business/view-settings.modals';
import { SystemConfig, SeasonalConfig, SeasonalDaysConfig, BaseResponse, DefaultGridType, Options } from '../../../shared/business/shared.modals';
import { BreakPointAccess } from '../../../shared/service/breakpoint.service';
import * as GlobalConst from '../../../shared/globalsContant';
import { SpaUtilities } from '../../../shared/utilities/spa-utilities';
import { SpaPropertyInformation } from '../../../core/services/spa-property-information.service';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { ZeroValueValidator } from 'src/app/shared/Validators/ZeroValueValidator';
import { AlertType, DmConfig } from 'src/app/common/Models/common.models';
import { DMConfigDataService } from 'src/app/common/dataservices/datamagine-config.data.service';
import { CommonUtilities } from 'src/app/common/shared/shared/utilities/common-utilities';
import { ButtonType } from 'src/app/ag-common/enums';
import { NotificationConfigurationService } from 'src/app/common/templates/notification-configuration/notification-configuration.service';
import { EventNotification } from 'src/app/common/templates/notification-configuration/notification-configuration.model';
import { DropdownOptions } from 'src/app/common/Models/ag-models';


@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [SystemConfigBusinessService]
})
export class AppointmentComponent extends SpaFormAgent implements OnInit, OnDestroy {
  enableSave: boolean;
  settingInfo: SystemConfiguration[] = [];
  seasonalSettingInfo: any = [];
  captions: any;
  operatingHours: any[];
  appointmentInfo: UntypedFormGroup;
  IntakeFormGroup: UntypedFormGroup;
  EFormGroup: UntypedFormGroup;
  isSeasonalSet: boolean;
  lunchSetup: any;
  staffDefaults: any;
  ScheduleData: any;
  blankDateTime: any = "HH:mm";
  seasonalDailySetup: UntypedFormArray;
  isApplyAllSelected: boolean;
  defaultStartTime = '';
  defaultEndtime = '';
  isUserAuthorized: boolean = true;
  isViewOnly: boolean = true;
  colorJson: SystemConfiguration[] = [];
  classcolorJson: SystemConfiguration[] = [];
  colorJsonCancelCloner: SystemConfiguration[] = [];
  colorCodeJSON: any = [];
  classcolorCodeJSON: any = [];
  clientCategories: any;
  hotelReservation: any;
  clientCategoryId: any;
  hotelReservationId: any;
  existingClientOptions:any;
  existingclientCategoryId : any;
  APPOINMENT_OPENTIME: any;
  APPOINMENT_CLOSETIME: any;
  settingCaptions: any = this.localization.captions.setting;
  floatLabel: string;
  dmConfig: DmConfig;
  operatingHoursInput: any[];
  allopenTime: any;
  allcloseTime: any;
  defaultLostDeniedReason;
  defaultLostDeniedReasonId: any;
  IsResortFinanceEnabled : boolean = false;
  PropertyOperateTime = {
    StartTime: "00:00",
    EndTime: "23:59"
    };
  notificationSetup:any;
  DisableEmail:boolean=false;
  displayTherapist: { id: number; name: any; }[];
  gridArray: any = [
    { "id": 2, "name": this.settingCaptions.FitToGrid, "value": false, "controlName": "APPOINMENT_FITLOCATIONTOGRID" },
    { "id": 5, "name": this.settingCaptions.AlwaysShowOverlaps, "value": true, "controlName": "APPOINMENT_ALWAYSSHOWOVERLAPS" },
    { "id": 6, "name": this.settingCaptions.AppointmentGridRefresh, "value": true, "controlName": "APPOINTMENT_ACTION_AUTO_REFRESH" }];

  spaWizardOptionArray: any = [
      { "id": 1, "name": this.settingCaptions.spaWizard_bookLunch, "controlName": "APPOINMENT_SPAWIZARDBOOKLUNCH" },
      { "id": 2, "name": this.settingCaptions.spaWizard_deposit, "controlName": "APPOINMENT_SPAWIZARDDEPOSIT" },
      { "id": 3, "name": this.settingCaptions.spaWizard_cancel, "controlName": "APPOINMENT_SPAWIZARDCANCEL" },
      { "id": 4, "name": this.settingCaptions.spaWizard_checkIn, "controlName": "APPOINMENT_SPAWIZARDCHECKIN" },
      { "id": 5, "name": this.settingCaptions.spaWizard_undoCheckIn, "controlName": "APPOINMENT_SPAWIZARDUNDOCHECKIN" },
      { "id": 6, "name": this.settingCaptions.spaWizard_checkOut, "controlName": "APPOINMENT_SPAWIZARDCHECKOUT" },
      { "id": 7, "name": this.settingCaptions.spaWizard_print, "controlName": "APPOINMENT_SPAWIZARDPRINT" }
    ];

      
  defaultGridArray: any = [
    { "id": DefaultGridType.Standard, "name": this.settingCaptions.Standard, "value": true, "controlName": "APPOINMENT_STANDARD" },
    { "id": DefaultGridType.Minimal, "name": this.settingCaptions.Minimal, "value": false, "controlName": "APPOINMENT_MINMAL" },
    { "id": DefaultGridType.Compact, "name": this.settingCaptions.Compact, "value": false, "controlName": "APPOINMENT_COMPACT" }];

  appointmentDetailArray: any = [
    { "id": 1, "name": this.settingCaptions.RequireGenderPreference, "value": false, "controlName": "APPOINMENT_REQUIREGENEDERPREFRENCE" },
    { "id": 2, "name": this.settingCaptions.RequireGuaranteeMethodForAppointment, "value": false, "controlName": "APPOINTMENT_REQUIRE_GUARANTEE_METHOD" },
    { "id": 3, "name": this.settingCaptions.DisplayAppointmentRecap, "value": true, "controlName": "APPOINTMENT_SHOW_RECAP" }
  ];
  cancellationArray: any = [{ "id": 1, "name": this.settingCaptions.DisplayCancellationNumber, "value": false, "controlName": "APPOINMENT_DISPLAYCANCELATIONNUMBER" }];
  seasonalHourSubscription: ISubscription;
  appointmentInfoSubscription: ISubscription;
  IntakeFormGroupSubscription: ISubscription;
  EFormGroupSubscription: ISubscription;

  intakeFormTypes: any = [{ "id": "1", "name": "WORD" }, { "id": "2", "name": "PDF" }, { "id": "3", "name": "HTML" }];
  outletOptions: DropdownOptions[] = [];
  cancellationNoShowSaveValidation: boolean = true;

  constructor(public localization: SpaLocalization, private BP: BreakPointAccess, private utilities: SpaUtilities, private fb: UntypedFormBuilder,
    private systemConfig: SystemConfigBusinessService, public http: HttpServiceCall,private utils: CommonUtilities, private PropertyInfo: SpaPropertyInformation,
    private dmConfigDataService: DMConfigDataService,private _NotificationConfigurationService: NotificationConfigurationService) {
    super(http);
    this.floatLabel = this.localization.setFloatLabel;
    this.IsResortFinanceEnabled = this.PropertyInfo?.isResortFinanceEnabled;
  }

  ngOnInit() {
    this.appointmentInfo = this.fb.group({
      // APPOINMENT_OPENTIME: '',
      // APPOINMENT_CLOSETIME: '',
      APPOINMENT_GRIDREFRESHTIME: 0,
      APPOINMENT_GRIDTIMEINTERVAL: 0,
      // APPOINTMENT_INTERVAL: 0,
      APPOINMENT_NOSHOWGRACE: 0,
      SET_SEASONAL_HOURS: false,
      startDate: '',
      endDate: '',
      applyTime: '',
      overAllOpenTime: '',
      overAllCloseTime: '',
      seasonalDailySetup: this.fb.array([]),
      APPOINTMENT_LUNCH_SERVICE: '',
      APOINTMENT_LUNCH_STAFF: '',
      APPOINTMENT_LUNCH_LOCATION: '',
      APPOINMENT_DEFAULTLUNCHDURATION: '',
      APPOINMENT_HIDELUNCH: '',
      ALLOW_APPOINTMENT_AFTER_SPATIMINGS: '',
      REQUIRE_NO_SHOW_REASON: '',
      ADVANCED_APPOINTMENT_SEARCH:false,
      APPOINTMENT_MODIFY_THERAPIST_VIEW:false,
      DEFAULT_NO_SHOW_REASON: '',
      APPOINMENT_STAFFSCHEDULESHIFTDURATION: '',
      APPOINMENT_BREAKDURATION: '',
      APPOINMENT_MINUTESAFTERSHIFTSTARTFORBREAK: '',
      STAFFSCHEDULE_MAXIMUMBREAKSDURINGSCHEDULE: '',
      APPOINTMENT_REQUIRE_SERVICE_POINTS: false,
      APPOINTMENT_LOST_DENIED_BUSINESS: false,
      APPOINMENT_SYSTEMDEFAULTCANCELLATIONPOLICY: '',
      TEMP_AUTO_RELEASETIME: 0,
      TEMP_AUTO_RELEASETIME_WIZARD: 0,
      grid: this.fb.array([this.addGridDetails()]),
      APPOINTMENT_DEFAULT_GRID_DISPLAY: DefaultGridType.Standard,
      appointmentDetail: this.fb.array([this.addAppointmentDetails()]),
      cancellation: this.fb.array([this.addCancellationDetails()]),
      SHOW_ALL_APPOINTMENTS_IN_CHECKIN: false,
      INTAKE_FORM_TYPE: 0,
      AUTOPICK_THERAPIST_LOCATION_ON_COPYMOVE: false,
      CONFIRMATION_DIALOG: false,
      AUTO_PICK_LOCATION: false,
      EXCLUDE_APPOINTMENT_COMMENTS: false,
      AUTO_CREATE_FOLIO_AT_CHECKIN: false,
      displayTherapist: 1,
      APPOINTMENT_MALE_THERAPIST:"",
      APPOINTMENT_FEMALE_THERAPIST:"",
      DEFAULT_CLIENT_CATEGORY: 0,
      DEFAULT_EXISTING_CLIENT_CATEGORY:0,    
      EXTENTED_PROFILE_SEARCH: true,
      DEFAULT_LOST_DENIED_REASONS:0,
        DEFAULT_HOTEL_RESERVATION: 0,
        SEND_AUTOMATIC_APPOINTMENT_NOTIFICATION: false,
        APPOINMENT_SPAWIZARDOPTIONS:'',
        APPOINMENT_SPAWIZARDBOOKLUNCH: '',
        APPOINMENT_SPAWIZARDDEPOSIT: '',
        APPOINMENT_SPAWIZARDCANCEL: '',
        APPOINMENT_SPAWIZARDCHECKIN: '',
        APPOINMENT_SPAWIZARDUNDOCHECKIN: '',
        APPOINMENT_SPAWIZARDCHECKOUT: '',
        APPOINMENT_SPAWIZARDPRINT: '',
      operatingHoursFormarray: this.fb.array([
        // this.fb.group({
        //   // APPOINMENT_OPENTIME : '',
        //   // APPOINMENT_CLOSETIME : ''
        // })
      ]),
      PRINT_ALL_ITINERARY:false,
      UPDATE_CUSTOM_FEE:false,     
      RESTRICT_EXCESS_DEPOSIT_PRICE:false,
      CANCELLATION_NOSHOW_FEE: false,
      DEFAULT_OUTLET_FOR_CANCELLATIONNOSHOW: 0,
      ENABLE_LINKED_APPOINTMENTS: false,
      DISABLE_SERVICE_IN_PACKAGE_APPOINTMENT:false
    });
    this.IntakeFormGroup = this.fb.group({
      INTAKE_FORM: false
    });
    this.EFormGroup = this.fb.group({
      E_FORM: false
    });
    this.ValidateBreakPoint();
    this.captions = this.localization.captions;
    this.clientCategories = this.getClientCategory();
    this.hotelReservation = this.getHotelReservation();
    this.existingClientOptions = this.getDefaultClientSearchFilter();
     this.getLostDeniedReasons();
    this.GetSettingData();

    this.colorCodeJSON = [{ "id": 1, "colorName": this.settingCaptions.CheckedIn, "colorCode": "#12343", "switchName": "APPOINMENT_CHECKEDIN_COLOR" }, { "id": 2, "colorName": this.settingCaptions.CheckedOut, "colorCode": "#124521", "switchName": "APPOINMENT_CHECKEDOUT_COLOR" }, { "id": 3, "colorName": this.settingCaptions.Open, "colorCode": "#102030", "switchName": "APPOINMENT_OPEN_COLOR" },
    { "id": 4, "colorName": this.settingCaptions.Break, "colorCode": "#EFECED", "switchName": "APPOINMENT_BREAK_COLOR" }, { "id": 5, "colorName": this.settingCaptions.Scheduled, "colorCode": "#222222", "switchName": "APPOINMENT_SCHEDULED_COLOR" }, { "id": 6, "colorName": this.settingCaptions.Closed, "colorCode": "#AFADAC", "switchName": "APPOINMENT_CLOSED_COLOR" },
    { "id": 7, "colorName": this.settingCaptions.Temp, "colorCode": "#142353", "switchName": "APPOINMENT_TEMP_COLOR" }, { "id": 8, "colorName": this.settingCaptions.Wait, "colorCode": "#111111", "switchName": "APPOINMENT_WAIT_COLOR" }, { "id": 9, "colorName": this.settingCaptions.BreakDown, "colorCode": "#951236", "switchName": "APPOINMENT_BREAKDOWN_COLOR" },
    { "id": 10, "colorName": this.settingCaptions.SetUp, "colorCode": "#142353", "switchName": "APPOINMENT_SETUP_COLOR" }, { "id": 11, "colorName": this.settingCaptions.NoShow, "colorCode": "#142353", "switchName": "APPOINMENT_NOSHOW_COLOR" }, { "id": 12, "colorName": this.settingCaptions.Cancel, "colorCode": "#142353", "switchName": "APPOINMENT_CANCEL_COLOR" }, { "id": 13, "colorName": this.settingCaptions.OnCall, "colorCode": "#FFFF00", "switchName": "APPOINMENT_ONCALL_COLOR" }, { "id": 14, "colorName": this.settingCaptions.LocationBlock, "colorCode": "#FFFF00", "switchName": "LOCATION_BLOCK_COLOR" }];
  
    this.classcolorCodeJSON = [
      { "id": 1, "colorName": this.settingCaptions.Scheduled, "colorCode": "#4ECDC4", "switchName": "CLASS_SCHEDULED_COLOR" },
       { "id": 2, "colorName": this.settingCaptions.Halted, "colorCode": "#FFFF80", "switchName": "CLASS_HALTED_COLOR" },
        { "id": 3, "colorName": this.settingCaptions.InPrgoress, "colorCode": "#2DCC70", "switchName": "CLASS_INPROGRESS_COLOR" },
      { "id": 4, "colorName": this.settingCaptions.Completed, "colorCode": "#F17935", "switchName": "CLASS_COMPLETED_COLOR" },
       { "id": 5, "colorName": this.settingCaptions.Cancel, "colorCode": "#6C7988", "switchName": "CLASS_CANCEL_COLOR" }];
  
    this.operatingHours = [
      {
        id: 1,
        placeholderValue: this.settingCaptions.AppointmentRefresh,
        controlName: "APPOINMENT_GRIDREFRESHTIME",
      },
      {
        id: 2,
        placeholderValue: this.settingCaptions.GridInterval,
        controlName: "APPOINMENT_GRIDTIMEINTERVAL",
      },
      {
        id: 3,
        placeholderValue: this.settingCaptions.GracePeriod,
        controlName: "APPOINMENT_NOSHOWGRACE",
      }
    ];
    this.displayTherapist=[
      {
        id:1,
        name:this.settingCaptions.displayTherapistName
      },
      {
        id:2,
        name:this.settingCaptions.displayTherapistPicture
      }
    ];
    this.lunchSetup = [
      {
        id: 1,
        placeholderValue: this.settingCaptions.lunchService,
        controlName: "APPOINTMENT_LUNCH_SERVICE"
      },
      {
        id: 2,
        placeholderValue: this.settingCaptions.lunchStaff,
        controlName: "APOINTMENT_LUNCH_STAFF"
      },
      {
        id: 3,
        placeholderValue: this.settingCaptions.lunchLocation,
        controlName: "APPOINTMENT_LUNCH_LOCATION"
      },
      {
        id: 4,
        placeholderValue: this.settingCaptions.defLunchDuration,
        controlName: "APPOINMENT_DEFAULTLUNCHDURATION"
      }
    ];
    this.staffDefaults = [
      {
        id: 1,
        placeholderValue: this.settingCaptions.shiftDuration,
        controlName: "APPOINMENT_STAFFSCHEDULESHIFTDURATION"
      },
      {
        id: 2,
        placeholderValue: this.settingCaptions.breakDuration,
        controlName: "APPOINMENT_BREAKDURATION"
      },
      {
        id: 3,
        placeholderValue: this.settingCaptions.shiftStart,
        controlName: "APPOINMENT_MINUTESAFTERSHIFTSTARTFORBREAK"
      },
      {
        id: 4,
        placeholderValue: this.settingCaptions.maxnoofbreaks,
        controlName: "STAFFSCHEDULE_MAXIMUMBREAKSDURINGSCHEDULE"
      }
    ]
    this.operatingHoursInput = [
      {
        day: this.settingCaptions.AllDay
      }
    ]

    this.LocalizedWeekDays();
    let arr = this.appointmentInfo.get('operatingHoursFormarray') as UntypedFormArray;
    this.isSeasonalSet = false;
    this.ScheduleData = this.formScheduleData();
    for (let i = 0; i < this.ScheduleData.length; i++) {
      this.seasonalDailySetup = this.appointmentInfo.get('seasonalDailySetup') as UntypedFormArray;
      this.seasonalDailySetup.push(this.addSeasonalTime(this.ScheduleData[i].id, this.ScheduleData[i].startTime, this.ScheduleData[i].endTime));
    }
    this.isApplyAllSelected = this.appointmentInfo.get('applyTime').value;
    this.seasonalHourSubscription = this.appointmentInfo.controls['SET_SEASONAL_HOURS'].valueChanges.subscribe(() => { this.onSeasonalChange(); })
    this.appointmentInfoSubscription = this.appointmentInfo.valueChanges.subscribe(makeDirty => {
      if (!this.appointmentInfo.controls['REQUIRE_NO_SHOW_REASON'].value)
        this.enableSave = true;
      else
        this.appointmentInfo.controls['DEFAULT_NO_SHOW_REASON'].value != '' ? this.enableSave = true : this.enableSave = false;
      //cancellation no show validation
      if (!this.appointmentInfo.controls['CANCELLATION_NOSHOW_FEE'].value)
        this.cancellationNoShowSaveValidation = true
      else
        this.cancellationNoShowSaveValidation = this.appointmentInfo.controls['DEFAULT_OUTLET_FOR_CANCELLATIONNOSHOW'].value != 0 ? true : false;
    });
    this.IntakeFormGroupSubscription = this.IntakeFormGroup.valueChanges.subscribe(makeDirty => {
      this.enableSave = true;
    });
    this.EFormGroupSubscription = this.EFormGroup.valueChanges.subscribe(makeDirty => {
      this.enableSave = true;
    });
    this.setValidators();
    this.GetNotificationDetails();
  }

  async GetNotificationDetails()
  {
    this.notificationSetup=await this._NotificationConfigurationService.GetEventNotificationGroupByProduct();
    if((this.notificationSetup.find(x=>x.eventId==EventNotification.AppointmentConfirmation && (x.sendSMS==true || x.sendMail==true)))||(this.notificationSetup.find(x=>x.eventId==EventNotification.AppointmentUpdate && (x.sendSMS==true || x.sendMail==true))))
    {
      this.DisableEmail=true;
    }
  }

  createOperatingHours(x, index, operatingHours) {
    if (index == 0)
      return this.fb.group({
        days: x.day,
        APPOINMENT_OPENTIME: this.fb.control(this.localization.DeLocalizeTime(this.PropertyOperateTime.StartTime)),
        APPOINMENT_CLOSETIME: this.fb.control(this.localization.DeLocalizeTime(this.PropertyOperateTime.EndTime))
      })

    return this.fb.group({
      days: x.day,
      APPOINMENT_OPENTIME: this.fb.control(this.localization.DeLocalizeTime(operatingHours[index - 1][1]['StartTime'])),//this.localization.DeLocalizeTime(operatingHours[index-1][1]['StartTime']),
      APPOINMENT_CLOSETIME: this.fb.control(this.localization.DeLocalizeTime(operatingHours[index - 1][1]['EndTime']), Validators.min(operatingHours[index - 1][1]['StartTime']))
    })
  }

 
  SpaWizardOptionChange(e,controlName){
    this.appointmentInfo.controls[controlName].setValue(e[0]);
  }


  apply(e) {
    if (!e[0])
      return;
    let arr = this.appointmentInfo.get('operatingHoursFormarray') as UntypedFormArray;
    this.operatingHoursInput.forEach((obj, idx) => {
      const group = arr.controls[idx] as UntypedFormGroup;
      if (idx == 0) {
        if (group) {
          this.allopenTime = group.controls.APPOINMENT_OPENTIME.value;
          this.allcloseTime = group.controls.APPOINMENT_CLOSETIME.value;
        }
      }
      else {
        group.controls.APPOINMENT_OPENTIME.patchValue(this.allopenTime);
        group.controls.APPOINMENT_CLOSETIME.patchValue(this.allcloseTime);
      }
    })
  }
  setValidators() {
    this.appointmentInfo.controls['STAFFSCHEDULE_MAXIMUMBREAKSDURINGSCHEDULE'].setValidators([Validators.required, ZeroValueValidator]);
  }

  ngOnDestroy() {
    if (this.seasonalHourSubscription) {
      this.seasonalHourSubscription.unsubscribe();
    }
    if (this.appointmentInfoSubscription) {
      this.appointmentInfoSubscription.unsubscribe();
    }
    if (this.IntakeFormGroupSubscription) {
      this.IntakeFormGroupSubscription.unsubscribe();
    }
    if (this.EFormGroupSubscription) {
      this.EFormGroupSubscription.unsubscribe();
    }
  }

  isClosed(event) {
    document.getElementById('time-overlay').classList.remove('transform3dnone');
  }

  openPicker(event) {
    document.getElementById('time-overlay').classList.add('transform3dnone');
  }

  GetSwitchName(_switch: string) {
    return this.colorCodeJSON.find(val => val.switchName == _switch).colorName;
  }

  GetColorSwitchName(_switch: string) {
    return this.classcolorCodeJSON.find(val => val.switchName == _switch).colorName;
  }
  async noshowChange(e) {

  }
  async modifyTherapistViewChange(e)
  {

  }

  ValidateBreakPoint(): void {
    this.isUserAuthorized = this.BP.CheckForAccess([GlobalConst.SPAScheduleBreakPoint.SettingSystemSettings]);
    this.isViewOnly = this.BP.IsViewOnly(GlobalConst.SPAScheduleBreakPoint.SettingSystemSettings);
    if (this.isViewOnly) {
      this.utilities.disableControls(this.appointmentInfo);
    }
  }

  async EFormToggleChange(e) {
    if (e[0]) {
      this.dmConfig = await this.dmConfigDataService.getDataMagineConfigSession();
      if (this.dmConfig.dmEformsConfig.enableEforms && this.dmConfig.enableDataMagine) {

        this.EFormGroup.controls['E_FORM'].setValue(true);
        if (this.appointmentInfo.dirty) {
          this.enableSave = false;
        }

      }
    }
  }

  async updateCustomFee(e) {
    this.EFormGroup.controls['E_FORM'].setValue(true);
    if (this.appointmentInfo.dirty) {
      this.enableSave = false;
    }
  }

  async updateCancellationNoShowToggle(e){
    if(e[0]){
      if(this.outletOptions.length == 0)
        this.getOutletDetails();
    }
  }

  async IntakeFormToggleChange(e) {
    if (e[0]) {
      this.dmConfig = await this.dmConfigDataService.getDataMagineConfigSession();
      if (this.dmConfig.dmEformsConfig.enableEforms && this.dmConfig.enableDataMagine) {
        this.utils.showAlert(this.captions.warn_ifEformExist, AlertType.Warning, ButtonType.Ok, (res) => {
          if (res) {
            this.IntakeFormGroup.controls['INTAKE_FORM'].setValue(false);
            if (!this.appointmentInfo.dirty) {
              this.enableSave = false;
            }
          }
        });
      }
    }
  }

  public getClientCategory(): Options[] {
    let clientCategoryOptions: Options[] = [
      { key: 0, label: this.captions.existingClients },
      { key: 3, label: this.captions.hotelReservation },
      { key: 2, label: this.captions.members }
    ];

    return clientCategoryOptions;
  }

  public getHotelReservation(): Options[] {
    let hotelReservationOptions: Options[] = [
      { key: 1, label: this.captions.confirmationNumber },
      { key: 2, label: this.captions.roomNumber },
      { key: 0, label: this.captions.guestName },
      { key: 3, label: this.captions.linkCode },
      { key: 4, label: this.captions.contactDetail }
    ];

    return hotelReservationOptions;
  }

  getDefaultClientSearchFilter(): Options[] {
    let existingClientOptions: Options[] = [
      { key: 0, label: this.localization.captions.firstName },
      { key: 1, label: this.localization.captions.lastName },
      { key: 2, label: this.localization.captions.name},
      { key: 3, label: this.localization.captions.phone },
      { key: 4, label: this.localization.captions.email }
    ];

    return existingClientOptions;
  }

  public getLostDeniedReasons() {
    var data = this.getLostDeniedReason(false).then(x=>{
      this.defaultLostDeniedReason=x.map(y=>{
        return {
          key:y.id,
          label: y.reasonCode
        }
      })
      let emptyKey={
        key:0,
        label:""
      }
      this.defaultLostDeniedReason.unshift(emptyKey);
   
    console.log("this.defaultLostDeniedReason",this.defaultLostDeniedReason)
    });
  }

  async getLostDeniedReason(includeinactive:boolean){
    const results = await this.http.CallApiAsync<any[]>({
      host: GlobalConst.Host.spaManagement,
      callDesc: "GetAllLostDeniedReasons",
      method: HttpMethod.Get,
      uriParams:{"IncludeInactive":includeinactive}
    });
    return results.result;
  }

  GetSettingData() {
    this.makeGetCall("GetAllSetting", GlobalConst.Host.spaManagement);
  }
  formScheduleData() {
    const weeks = moment.weekdays(true);
    let dataArr = [];
    for (let i = 1; i <= weeks.length; i++) {
      const day = weeks[i - 1];
      dataArr.push({
        id: i,
        day: day,
        startTime: '',
        endTime: ''
      });
    }
    return dataArr;
  }

  onSeasonalChange() {
    this.isSeasonalSet = this.appointmentInfo.controls['SET_SEASONAL_HOURS'].value;
  }

  addGridDetails(): UntypedFormGroup {
    return this.fb.group({
      APPOINMENT_NOSHOWAUTORELEASE: '',
      APPOINMENT_FITLOCATIONTOGRID: '',
      APPOINMENT_HIDESTAFFGENDER: '',
      APPOINMENT_SHOWGROUPBOOKING: '',
      APPOINMENT_ALWAYSSHOWOVERLAPS: '',
      APPOINTMENT_ACTION_AUTO_REFRESH: ''
    });
  }

  addAppointmentDetails(): UntypedFormGroup {
    return this.fb.group({
      APPOINMENT_REQUIREGENEDERPREFRENCE: '',
      APPOINTMENT_REQUIRE_GUARANTEE_METHOD: '',
      APPOINTMENT_SHOW_RECAP: ''
    });
  }

  addCancellationDetails(): UntypedFormGroup {
    return this.fb.group({
      APPOINMENT_DISPLAYCANCELATIONNUMBER: ''
    });
  }

  addSeasonalTime(i, startTime, endTime): UntypedFormGroup {
    return this.fb.group({
      id: i,
      workStartTime: startTime,
      workEndTime: endTime
    });
  }

  getHeight() {
    let result = document.getElementById('gridsection').offsetHeight;
    let result1 = document.getElementById('appointmentsection').offsetHeight;
    let result2 = document.getElementById('sidesection1').offsetHeight;
    let defaultGridSection = document.getElementById('defaultGridSection').offsetHeight;
    const largestHeight = Math.max(result, result1,result2, defaultGridSection);
    document.getElementById('gridsection').style.height = largestHeight + 'px';
    document.getElementById('appointmentsection').style.height = largestHeight + 'px';
    document.getElementById('defaultGridSection').style.height = largestHeight + 'px';
    document.getElementById('sidesection1').style.height = largestHeight + 'px';
  }

  applyTimeForAll(e): void {
    if (e[0]) {
      let seasonalDailySetup = this.appointmentInfo.get('seasonalDailySetup') as UntypedFormArray;
      let startTime: any = document.getElementById('startTime');
      let endTime: any = document.getElementById('closeTime');
      for (let i = 0; i < this.ScheduleData.length; i++) {
        seasonalDailySetup.controls[i].get('workStartTime').setValue(
          startTime.value
        );
        seasonalDailySetup.controls[i].get('workEndTime').setValue(
          endTime.value
        );
      }
    }
  }

  setSeasonalTime(_startTime: string, _endTime: string, index: number) {
    let seasonalDailySetup = this.appointmentInfo.get('seasonalDailySetup') as UntypedFormArray;
    seasonalDailySetup.controls[index].get('workStartTime').setValue(
      _startTime
    );
    seasonalDailySetup.controls[index].get('workEndTime').setValue(
      _endTime
    );
  }
  cancel() {
    let operatingHoursString = this.settingInfo.find(x => x.switch == 'APPOINTMENT_OPERATING_HOURS');
    let spaWizardOptionString = this.settingInfo.find(x => x.switch == 'APPOINMENT_SPAWIZARDOPTIONS');
    let DisplayTherapistNumber = this.settingInfo.find(x => x.switch == 'APPOINTMENT_DISPLAY_THERAPIST').value;
    if (operatingHoursString) {
      let arr = this.appointmentInfo.get('operatingHoursFormarray') as UntypedFormArray;
      arr.clear();
      let appointmentOperatingHours = Object.entries(JSON.parse(operatingHoursString.value as string));
      this.operatingHoursInput.forEach((cvalue, index, array) => {
        arr.insert(index, this.createOperatingHours(cvalue, index, appointmentOperatingHours));
      });
    };
    if(spaWizardOptionString){
      let appointmentspaWizardOptions = Object.entries(JSON.parse(this.systemConfig.systemConfigValues.APPOINMENT_SPAWIZARDOPTIONS));
      appointmentspaWizardOptions.forEach((obj,i)=>{
        this.appointmentInfo.controls[obj[0]].setValue(obj[1]=="true" ||obj[1]==true ? true:false);
      })
    }
    this.appointmentInfo.patchValue(this.systemConfig.systemConfigValues);
    this.IntakeFormGroup.patchValue(this.systemConfig.systemConfigValues);
    this.EFormGroup.patchValue(this.systemConfig.systemConfigValues);
    this.isSeasonalSet = this.appointmentInfo.get('SET_SEASONAL_HOURS').value;
    this.appointmentInfo.controls.displayTherapist.setValue(DisplayTherapistNumber?Number(DisplayTherapistNumber):0);
    this.enableSave = false;
  }

  save() {
    let bodyData: SystemConfig[],
      appointmentStatus: SystemConfiguration[],
      seasonSettingData: SeasonalConfig,
      classStatus: SystemConfiguration[];
    try {
      bodyData = this.formPropertyBody();
      appointmentStatus = this.colorJson;
      classStatus = this.classcolorJson;
      seasonSettingData = this.formSeasonalPropertyBody();
      appointmentStatus = appointmentStatus.filter(val => val.updated);
      classStatus = classStatus.filter(val=>val.updated);
      if (appointmentStatus.length || classStatus.length) {
        bodyData = [...bodyData, ...appointmentStatus, ...classStatus];
      }
      this.UpdateSetting(bodyData);
      this.UpdateSeasonalSetting(seasonSettingData);
    }
    catch (ex) {
      console.error(ex);
    }
  }
  UpdateSetting(bodyData) {
    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "UpdateSetting",
      body: bodyData,
      method: HttpMethod.Put,
      showError: true,
      extraParams: []
    });
  }
  UpdateSeasonalSetting(seasonSettingData) {
    this.http.CallApiWithCallback<any>({
      host: GlobalConst.Host.spaManagement,
      success: this.successCallback.bind(this),
      error: this.errorCallback.bind(this),
      callDesc: "UpdateSeasonalSetting",
      body: seasonSettingData,
      method: HttpMethod.Put,
      showError: true,
      extraParams: []
    });
  }
  formPropertyBody(): SystemConfig[] {
    let allControls: string[] = Object.keys(this.appointmentInfo.controls);
    let _body: SystemConfig[] = [];
    for (let i = 0; i < allControls.length; i++) {
      let _control: string = allControls[i];
      let value: string | boolean;
      if (_control == 'grid' || _control == 'seasonalDailySetup' || _control == 'appointmentDetail' || _control == 'cancellation' || _control == 'startDate' || _control == 'endDate' || _control == 'applyTime' || _control == 'overAllOpenTime' || _control == 'overAllCloseTime' 
       || _control =='APPOINMENT_SPAWIZARDBOOKLUNCH' || _control =='APPOINMENT_SPAWIZARDDEPOSIT' ||_control=='APPOINMENT_SPAWIZARDCANCEL' || _control =='APPOINMENT_SPAWIZARDCHECKIN' || _control == 'APPOINMENT_SPAWIZARDUNDOCHECKIN' || _control =='APPOINMENT_SPAWIZARDCHECKOUT'
       || _control =='APPOINMENT_SPAWIZARDPRINT')
        continue;
      let _systemConfig: SystemConfig;
      if (_control == 'operatingHoursFormarray') {
        let appointmentOperatingHours: Object = {};
        const days = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"]//moment.weekdays(false);
        this.appointmentInfo.controls[_control].value.forEach((cvalue, index, array) => {
          if (index > 0) {
            appointmentOperatingHours[days[index - 1]] = {
              'StartTime': this.localization.DeLocalizeTime(cvalue.APPOINMENT_OPENTIME),
              'EndTime': this.localization.DeLocalizeTime(cvalue.APPOINMENT_CLOSETIME)
            }
          }
        });
        value = JSON.stringify(appointmentOperatingHours);
        _control = 'APPOINTMENT_OPERATING_HOURS';
        //value = this.localization.DeLocalizeTime(this.appointmentInfo.controls[_control].value);
      }
      else if(_control == 'APPOINMENT_SPAWIZARDOPTIONS'){
        let appointmentSpaWizardOption: Object = {};

        this.spaWizardOptionArray.forEach((cvalue, index, array)=>{
          appointmentSpaWizardOption[cvalue.controlName]=
           this.appointmentInfo.controls[cvalue.controlName].value.toString()
          
        })
        value = JSON.stringify(appointmentSpaWizardOption);
        _control = 'APPOINMENT_SPAWIZARDOPTIONS';
      }
      else if (_control == 'APPOINMENT_NOSHOWGRACE' || _control == "APPOINMENT_GRIDTIMEINTERVAL"  || _control == "APPOINMENT_GRIDREFRESHTIME" ||  _control == "TEMP_AUTO_RELEASETIME"
        ||  _control == "TEMP_AUTO_RELEASETIME_WIZARD") {
        value = this.appointmentInfo.controls[_control].value == "" ? 0 : this.appointmentInfo.controls[_control].value
      }
      else if(_control =='displayTherapist')
      {
        value = this.appointmentInfo.controls[_control].value;
        _control = 'APPOINTMENT_DISPLAY_THERAPIST';
      }
      else {
        value = this.appointmentInfo.controls[_control].value;
      }
      console.log(_control);
      console.log(this.settingInfo.find(s => s.switch == _control));
      _systemConfig = {
        id: this.settingInfo.find(s => s.switch == _control).id,
        moduleId: this.settingInfo.find(s => s.switch == _control).moduleId,
        switch: _control,
        value: value
      }
      _body.push(_systemConfig);


    }

    let intakeform = {
      id: this.settingInfo.find(s => s.switch == "INTAKE_FORM").id,
      moduleId: this.settingInfo.find(s => s.switch == "INTAKE_FORM").moduleId,
      switch: "INTAKE_FORM",
      value: this.IntakeFormGroup.controls["INTAKE_FORM"].value
    }
    _body.push(intakeform);

    let eform = {
      id: this.settingInfo.find(s => s.switch == "REQUIREEFORM_DURINGCHECKIN").id,
      moduleId: this.settingInfo.find(s => s.switch == "REQUIREEFORM_DURINGCHECKIN").moduleId,
      switch: "REQUIREEFORM_DURINGCHECKIN",
      value: this.EFormGroup.controls["E_FORM"].value
    }
    _body.push(eform);

    // let updateCustomFee = {
    //   id: this.settingInfo.find(s => s.switch == "UPDATE_CUSTOM_FEE").id,
    //   moduleId: this.settingInfo.find(s => s.switch == "INTAKE_FORM").moduleId,
    //   switch: "UPDATE_CUSTOM_FEE",
    //   value: this.IntakeFormGroup.controls["UPDATE_CUSTOM_FEE"].value
    // }
    // _body.push(intakeform);

    for (let i = 0; i < allControls.length; i++) {
      let _grid = ['APPOINMENT_ALWAYSSHOWOVERLAPS', 'APPOINMENT_FITLOCATIONTOGRID', 'APPOINMENT_HIDESTAFFGENDER', 'APPOINMENT_NOSHOWAUTORELEASE', 'APPOINMENT_SHOWGROUPBOOKING', 'APPOINTMENT_ACTION_AUTO_REFRESH'];
      let _apt = ['APPOINMENT_REQUIREGENEDERPREFRENCE', 'APPOINTMENT_SHOW_RECAP', 'APPOINTMENT_REQUIRE_GUARANTEE_METHOD'];
      let _cancel = ['cancellation'];
      let _control: string = allControls[i];
      let _systemConfig: SystemConfig;
      if (_control == 'grid' || _control == 'seasonalDailySetup' || _control == 'appointmentDetail' || _control == 'cancellation') {
        {
          if (_control == 'grid') {
            for (let temp = 0; temp < _grid.length; temp++) {
              let grid = _grid[temp];
              let systemConfig: SystemConfig;
              systemConfig = {
                id: this.settingInfo.find(s => s.switch == grid).id,
                moduleId: this.settingInfo.find(s => s.switch == grid).moduleId,
                switch: _grid[temp],
                value: this.appointmentInfo.controls['grid'].value[0][grid]
              }
              _body.push(systemConfig);
            }
          }
          if (_control == 'appointmentDetail') {
            for (let temp = 0; temp < _apt.length; temp++) {
              let grid = _apt[temp];
              let systemConfigData: SystemConfig;
              systemConfigData = {
                id: this.settingInfo.find(s => s.switch == grid).id,
                moduleId: this.settingInfo.find(s => s.switch == grid).moduleId,
                switch: _apt[temp],
                value: this.appointmentInfo.controls['appointmentDetail'].value[0][grid]
              }
              _body.push(systemConfigData);
            }
          }
          if (_control == 'cancellation') {
            for (let temp = 0; temp < _cancel.length; temp++) {
              let grid = "APPOINMENT_DISPLAYCANCELATIONNUMBER";
              let config: SystemConfig;
              config = {
                id: this.settingInfo.find(s => s.switch == grid).id,
                moduleId: this.settingInfo.find(s => s.switch == grid).moduleId,
                switch: grid,
                value: this.appointmentInfo.controls['cancellation'].value[0][grid]
              }
              _body.push(config);
            }
          }
        }
      }
    }

    return _body;
  }
  //dg
  formSeasonalPropertyBody(): SeasonalConfig {
    let _seasonalConfig: SeasonalConfig;
    let sDays: SeasonalDaysConfig[] = [];
    let seasonalDays = this.appointmentInfo.controls['seasonalDailySetup'] as UntypedFormArray;
    let workStartTime: any[] = [];
    let workEndTime: any[] = [];
    for (let index = 0; index < this.seasonalDailySetup.length; index++) {
      if (this.seasonalDailySetup.controls[index].get('workStartTime').value != "")
        workStartTime[index] = this.seasonalDailySetup.controls[index].get('workStartTime').value;
    }
    for (let index = 0; index < this.seasonalDailySetup.length; index++) {
      if (this.seasonalDailySetup.controls[index].get('workEndTime').value != "")
        workEndTime[index] = this.seasonalDailySetup.controls[index].get('workEndTime').value;
    }
    if (workStartTime.length > 0 || workEndTime.length > 0) {
      for (let index = 0; index < seasonalDays.length; index++) {
        let days: SeasonalDaysConfig;
        days = {
          id: (this.seasonalSettingInfo && this.seasonalSettingInfo.seasonaldays.length > 0) ? this.seasonalDailySetup.controls[index].get('id').value : 0,
          seasonalHourId: this.seasonalSettingInfo == null ? 1 : this.seasonalSettingInfo.id,
          day: this.ScheduleData[index].id,
          openTime: workStartTime[index] == "" ? "" : workStartTime[index],
          closeTime: workEndTime[index] == "" ? "" : workEndTime[index]
        };
        sDays.push(days);
      }
    }
    _seasonalConfig = {
      id: this.seasonalSettingInfo == null ? 0 : this.seasonalSettingInfo.id,
      startDate: this.appointmentInfo.controls['startDate'].value,
      endDate: this.appointmentInfo.controls['endDate'].value,
      isApplyForAllDays: this.appointmentInfo.controls['applyTime'].value,
      openTime: this.defaultStartTime,
      closeTime: this.defaultEndtime,
      seasonaldays: sDays
    }
    return _seasonalConfig;

  }



  ngAfterViewInit() {
    this.getHeight();
  }
  successCallback<T>(result: BaseResponse<T>, callDesc: string, extraParams?: any[]): void {
    if (callDesc == "GetSeasonalSetting") {
      this.seasonalSettingInfo = <any>result.result;
      if (this.seasonalSettingInfo == null) {
        this.defaultStartTime = this.localization.LocalizeTime(this.utilities.TimeToPropertyDateTime("09:00"));
        this.defaultEndtime = this.localization.LocalizeTime(this.utilities.TimeToPropertyDateTime("21:00"));
        this.appointmentInfo.controls.applyTime.setValue(false);
        this.appointmentInfo.controls.startDate.setValue(moment(this.PropertyInfo.CurrentDate).add(0, "days").format('YYYY-MM-DDTHH:mm:ss'));
        this.appointmentInfo.controls.endDate.setValue(moment(this.PropertyInfo.CurrentDate).add(6, "days").format('YYYY-MM-DDTHH:mm:ss'));
      }
      else {
        let arr: any = [{
          id: String,
          workStartTime: Date,
          workEndTime: Date
        }];
        if (this.seasonalSettingInfo["seasonaldays"] && this.seasonalSettingInfo["seasonaldays"].length > 0) {
          for (let i = 0; i < 7; i++) {
            var workStart = this.utilities.getDate(this.seasonalSettingInfo["seasonaldays"][i].openTime);
            var workEnd = this.utilities.getDate(this.seasonalSettingInfo["seasonaldays"][i].closeTime);
            arr = {
              id: this.seasonalSettingInfo["seasonaldays"][i].day,
              workStartTime: this.localization.getTime(this.utilities.getDate(this.localization.ConvertDateToISODate(this.PropertyInfo.CurrentDate) + "T" + this.utilities.getTime(workStart, 24)), 24),
              closeTime: this.localization.getTime(this.utilities.getDate(this.localization.ConvertDateToISODate(this.PropertyInfo.CurrentDate) + "T" + this.utilities.getTime(workEnd, 24)), 24)
            }
            this.setSeasonalTime(arr.workStartTime, arr.closeTime, i);
          }

        }

        var oTime = this.utilities.getDate(this.seasonalSettingInfo.openTime);
        var cTime = this.utilities.getDate(this.seasonalSettingInfo.closeTime);
        this.appointmentInfo.controls.startDate.setValue(this.seasonalSettingInfo.startDate);
        this.appointmentInfo.controls.endDate.setValue(this.seasonalSettingInfo.endDate);
        this.appointmentInfo.controls.applyTime.setValue(this.seasonalSettingInfo.isApplyForAllDays);
        this.defaultEndtime = this.localization.LocalizeTime(cTime);
        this.defaultStartTime = this.localization.LocalizeTime(oTime);
      }
      this.enableSave = false;
    }
    if (callDesc == "GetAllSetting") {

      this.settingInfo = <any>result.result;
      this.updateSessionDetails();
      let _appJSON: any = {};
      this.settingInfo.forEach(sc => {
        if (sc.switchType == "Boolean") {
          sc.value = this.convertStringToBoolean(sc.value as string);
        }
        _appJSON[sc.switch] = sc.value
      });
      if (_appJSON.APPOINTMENT_OPERATING_HOURS) {
        let arr = this.appointmentInfo.get('operatingHoursFormarray') as UntypedFormArray;
        arr.clear();
        let appointmentOperatingHours = Object.entries(JSON.parse(_appJSON.APPOINTMENT_OPERATING_HOURS));
        this.operatingHoursInput.forEach((cvalue, index, array) => {
          arr.push(this.createOperatingHours(cvalue, index, appointmentOperatingHours));
          //arr.at(index).setValidators([Validators.min(arr.controls[index].value.APPOINMENT_OPENTIME)]);
        })
      }
      this.systemConfig.systemConfigValues = _appJSON;
      this.appointmentInfo.controls.APPOINTMENT_DEFAULT_GRID_DISPLAY.setValue(_appJSON.APPOINTMENT_DEFAULT_GRID_DISPLAY);
      this.formAndPatchData(_appJSON);
      this.IntakeFormGroup.controls.INTAKE_FORM.setValue(_appJSON.INTAKE_FORM);
      this.EFormGroup.controls.E_FORM.setValue(_appJSON.REQUIREEFORM_DURINGCHECKIN);
      this.appointmentInfo.controls.APPOINTMENT_DEFAULT_GRID_DISPLAY.setValue(Number(_appJSON.APPOINTMENT_DEFAULT_GRID_DISPLAY));
      //this.appointmentInfo.controls.displayTherapist.setValue(_appJSON.)
      this.PropertyInfo.SetAppointmentConfigurations(_appJSON);
      if (this.appointmentInfo.controls['SET_SEASONAL_HOURS']) {
        this.makeGetCall("GetSeasonalSetting", GlobalConst.Host.spaManagement);
      }
      if(this.appointmentInfo.controls['CANCELLATION_NOSHOW_FEE'].value){
        this.getOutletDetails();
    }
      this.enableSave = false;
    }
    if (callDesc == "UpdateSetting") {
      this.GetSettingData();
      this.enableSave = false;

    }
    if (callDesc == "UpdateSeasonalSetting") {
      this.makeGetCall("GetSeasonalSetting", GlobalConst.Host.spaManagement);
      this.enableSave = false;
    }
  }

  patchSeasonalDailySetupValues(i: number, id: number, startTime: string, endTime: string) {
    this.seasonalDailySetup.at(i).setValue({
      id: id,
      workStartTime: startTime,
      workEndTime: endTime
    });
  }

  formAndPatchData(_appJSON) {
    let localSettingInfo = this.settingInfo;
    _appJSON['grid'] = [_appJSON];
    _appJSON['appointmentDetail'] = [_appJSON];
    _appJSON['cancellation'] = [_appJSON];
    this.appointmentInfo.patchValue(_appJSON);
    if(_appJSON.APPOINMENT_SPAWIZARDOPTIONS){
      let appointmentspaWizardOptions = Object.entries(JSON.parse(_appJSON.APPOINMENT_SPAWIZARDOPTIONS));
      appointmentspaWizardOptions.forEach((obj,i)=>{
        this.appointmentInfo.controls[obj[0]].setValue(obj[1]=="true" ||obj[1]==true ? true:false);
      })
    }
    this.appointmentInfo.controls.displayTherapist.setValue(_appJSON['APPOINTMENT_DISPLAY_THERAPIST']?Number(_appJSON['APPOINTMENT_DISPLAY_THERAPIST']):0);
    this.isSeasonalSet = this.appointmentInfo.controls.SET_SEASONAL_HOURS.value;
    this.clientCategoryId = _appJSON['DEFAULT_CLIENT_CATEGORY'];
    this.hotelReservationId = _appJSON['DEFAULT_HOTEL_RESERVATION'];
    this.defaultLostDeniedReasonId = _appJSON['DEFAULT_LOST_DENIED_REASONS'];
    this.existingclientCategoryId = _appJSON['DEFAULT_EXISTING_CLIENT_CATEGORY'];
    this.appointmentInfo.controls['DEFAULT_OUTLET_FOR_CANCELLATIONNOSHOW'].setValue(Number(_appJSON['DEFAULT_OUTLET_FOR_CANCELLATIONNOSHOW']));
    if (localSettingInfo.length) {
      let _colorCodeJSON = this.colorCodeJSON.map(val => val.switchName);
      for (let index = 0; index < localSettingInfo.length; index++) {
        if (_colorCodeJSON.includes(localSettingInfo[index].switch.toUpperCase())) {
          let idx = this.colorJson.findIndex(x => { return localSettingInfo[index].switch == x.switch; });
          if (idx != -1) {
            this.colorJson[idx] = localSettingInfo[index];
          }
          else {
            this.colorJson.push(localSettingInfo[index]);
          }
        }
      }


      let _classcolorCodeJSON = this.classcolorCodeJSON.map(val => val.switchName);
      for (let index = 0; index < localSettingInfo.length; index++) {
        if (_classcolorCodeJSON.includes(localSettingInfo[index].switch.toUpperCase())) {
          let idx = this.classcolorJson.findIndex(x => { return localSettingInfo[index].switch == x.switch; });
          if (idx != -1) {
            this.classcolorJson[idx] = localSettingInfo[index];
          }
          else {
            this.classcolorJson.push(localSettingInfo[index]);
          }
        }
      }

      this.colorJsonCancelCloner = _.cloneDeep(this.colorJson);
      
    }

  }

  updateSessionDetails() {
    let defaultsSetting = this.settingInfo.filter(s => s.moduleId == 2);
    sessionStorage.setItem('defaultSettings', JSON.stringify(defaultsSetting));
  }

  changeColor($event: any, idx: number): void {
    this.colorJson[idx].value = $event.color.hex;
    this.colorJson[idx].updated = true;
    this.enableSave = true;
  }

  changeClassStatusColor($event: any, idx: number): void {
    this.classcolorJson[idx].value = $event.color.hex;
    this.classcolorJson[idx].updated = true;
    this.enableSave = true;
  }


  changeColorMaleTherapist($event: any):void{
    this.appointmentInfo.controls.APPOINTMENT_MALE_THERAPIST.setValue($event.color.hex);
  }

  changeColorFemaleTherapist($event: any):void{
    this.appointmentInfo.controls.APPOINTMENT_FEMALE_THERAPIST.setValue($event.color.hex);
  }

  convertStringToBoolean(value: string): boolean {
    return value == "true" ? true : false;
  }

  errorCallback<T>(error: BaseResponse<T>, callDesc: string, extraParams: any[]): void {
    if (callDesc == "UpdateSetting" || callDesc == "UpdateSeasonalSetting") {
      this.enableSave = true;
    }
  }

  handleKeyup(e) {
    this.enableSave = this.appointmentInfo.controls['STAFFSCHEDULE_MAXIMUMBREAKSDURINGSCHEDULE'].valid;
  }

  compareObjects(o1: any, o2: any): boolean {
    return o2 && o1 == o2;
  }

  LocalizedWeekDays(){
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    moment.locale(this.localization.localeCode);
    days.forEach((cvalue, index, array)=>{
      this.operatingHoursInput[index+1] ={
        'day' : moment.weekdays(index)}
    })
  }

  async getOutletDetails() {
    let outlets = await this.utilities.GetOutletsByPropertyId()
    outlets = outlets.filter(x => x.isActive);
    this.outletOptions = outlets.map(x => { return { id: x.subPropertyID, value: x.subPropertyName, viewValue: x.subPropertyName.toString() } });
  }
}
